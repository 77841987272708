import Route from '@ember/routing/route'
import { inject as service } from '@ember/service';

export default class AdminLocationsEditRoute extends Route {
  @service session;

  model(params) {
    return this.store.findRecord('v2/location', params.location_id, {
      adapterOptions: {
        include: 'address,vehicles,general-price-list,general-price-list.chart-of-accounts,' +
                 'special-taxes,default-sfgs-logo,default-receipt-logo,default-soa-logo,' +
                 'client.contract-templates,client.soa-templates,managing-director,organizations'
      }
    });
  }

  beforeModel(transition) {
    if (transition.to.name == 'authenticated.admin.locations.edit.index') {
      this.transitionTo('authenticated.admin.locations.edit.overview');
    }
  }

  setupController(controller) {
    super.setupController(...arguments)

    const clientInfoId = this.session.get('session.authenticated.client.client_info_id');
    controller.set('clientInfo', this.store.findRecord('clientInfo', clientInfoId));
  }
}
