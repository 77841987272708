import { get, action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class KasesIntegrationsTukiosRoute extends Route {
  @service api;
  layout = 'custom-aside';

  model() {
    const model = this.modelFor('authenticated.kases.manage');

    return RSVP.hash({
      model,
      kase: model,
      tukiosExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'tukios',
        most_recent_export: true,
        status: 'successful'
      }),
    });
  }

  setupController(controller, model, _transition) {
    super.setupController(...arguments);
    const events = this.store.query('event', { kase_id: get(model, 'kase.id') });
    controller.set('events', events);
    this.populateTukiosThemes(controller, model.kase.id)
  }

  async populateTukiosThemes(controller, kase_id) {
    this.api.json.get(`tukios/themes/${kase_id}`).then(resp => {
      controller.set('tukiosThemes', resp.parsedJson.tukios_themes.sort());
    })
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
