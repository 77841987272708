import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsRoute extends Route {
  layout = 'custom-aside';
  queryParams = {
    redirectPath: {
      refreshModel: true
    }
  };
  
  @service session;

  async model() {
    const kase = await this.modelFor('authenticated.kases.manage');

    /**
     * If this is updated, also update the fetchIntegrationServices in the following file:
     * app/controllers/authenticated/kases/manage/integrations/index.js
     */
    return await this.store.query('integrationService', {
      kase_id: kase.id,
      not_category: ['accounting', 'answering_service'],
      per_page: 100,
      status: 1 // successful 
    });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);
 
    controller.setProperties({
      kase: this.modelFor('authenticated.kases.manage'),
      sortedIntegrationServices: this.sortIntegrationServices(model)
    });
  }

  sortIntegrationServices(integrationServices) {
    if (!integrationServices || integrationServices.length === 0) {
      return [];
    }

    return integrationServices.slice().sort((a, b) => {
      if (a.isTributeIntegration && !b.isTributeIntegration) {
        return -1;
      } else if (!a.isTributeIntegration && b.isTributeIntegration) {
        return 1;
      }

      return a.displayName.localeCompare(b.displayName);
    });
  }
}
