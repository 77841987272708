import { get, action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class KasesIntegrationsTukiosV2Route extends Route {
  @service api;
  layout = 'custom-aside';

  model() {
    const model = this.modelFor('authenticated.kases.manage');

    return RSVP.hash({
      model,
      kase: model,
      tukiosExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'tukios_v2',
        most_recent_export: true,
        status: 'successful'
      }),
    });
  }

  setupController(controller, model, _transition) {
    super.setupController(...arguments);
    const events = this.store.query('event', { kase_id: get(model, 'kase.id') });
    controller.set('events', events);
    this.populateTukiosCoverPhotos(controller)
  }

  async populateTukiosCoverPhotos(controller) {
    this.api.json.get('tukios_v2/cover_photos').then(resp => {
      controller.set('tukiosCoverPhotos', resp.parsedJson);
    })
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
