import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @dataTest={{@dataTest}}\n    @disabled={{@disabled}}\n    @onChange={{this.selectStatus}}\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{@placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    @value={{@value}}\n    class=\"c--xs--12\">\n  {{#each @options as |status|}}\n    <option value={{status.id}} selected={{eq @value status.id}}>\n        {{status.label}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @dataTest={{@dataTest}}\n    @disabled={{@disabled}}\n    @onChange={{this.selectStatus}}\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{@placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    @value={{@value}}\n    class=\"c--xs--12\">\n  {{#each @options as |status|}}\n    <option value={{status.id}} selected={{eq @value status.id}}>\n        {{status.label}}\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/selector/check-status.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/selector/check-status.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class GCraknSelectorCheckStatus extends Component {
  @action
  selectStatus(statusId) {
    const selectedStatus = this.args.options.findBy('id', statusId);
    this.args.onChange(selectedStatus);
  }
}
