import Model, { attr, belongsTo } from '@ember-data/model';

export default class V2AccountModel extends Model {
  @attr('string') accountType;
  @attr('string') generalLedgerId;
  @attr('string') name;
  @attr('string') remoteId;
  
  @attr('moment-datetime', { defaultValue: null }) archivedAt;

  @attr('number') qboProductServiceId;

  @belongsTo('v2/chartOfAccounts') chartOfAccounts;

  get typeName() {
    return this.accountType.replace('Plutus::', '');
  }
}
