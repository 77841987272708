/* eslint-disable ember/no-computed-properties-in-native-classes */

import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { buildValidations, validator } from 'ember-cp-validations';
import { inject as service } from '@ember/service';
import { reads } from '@ember/object/computed';
import { preventScientificNotation } from 'crakn/utils/numeric-input-helpers';

const Validations = buildValidations({
    'model.bankAccount.bankName': validator('presence', {
        presence: true
    }),
    'model.bankAccount.bankAccountName': validator('presence', {
        presence: true
    }),
    'model.bankAccount.accountNumberLastFour': validator('presence', {
        presence: true
    }),
    'model.bankAccount.routingNumberLastFour': validator('presence', {
        presence: true
    }),
    'model.bankAccount.generalLedgerAccountId': validator('presence', {
        presence: true
    }),
    'model.bankAccount.checkTemplateId':
    validator('presence', {
        presence: true,
        disabled: computed('model.bankAccount.isCheckSettingsEnabled', function () {
            return !(this.model.bankAccount.isCheckSettingsEnabled ?? false);
        })
    }),
    'model.bankAccount.startingCheckNumber':
        validator('number', {
            integer: true,
            gt: 0,
            allowString: true,
            message: reads('model.greaterThanOneValidationMessage'),
            disabled: computed('model.bankAccount.isCheckSettingsEnabled', function () {
                return !(this.model.bankAccount.isCheckSettingsEnabled ?? false);
            })
    }),
    'model.bankAccount.dateRange':
        validator('number', {
            integer: true,
            gte: 0,
            allowString: true,
            message: reads('model.nonNegativeNumberValidationMessage'),
            disabled: computed('model.bankAccount.{isCheckSettingsEnabled,isDateRangeEnabled}', function () {
                return !(this.model.bankAccount.isCheckSettingsEnabled && this.model.bankAccount.isDateRangeEnabled)
            })
    })
});

export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsEditController extends Controller.extend(Validations) {
    @service flashes;
    @service intl;
    @service router;
    @service api;

    @tracked bankName;
    @tracked selectedStaff = '';
    @tracked routingNumber;
    @tracked accountNumber;
    @tracked showStaffMemberSearch = false;
    @tracked showValidations = false;

    preventScientificNotation = preventScientificNotation;

    get greaterThanOneValidationMessage() {
        return this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.messages.saveEdit.validation.number.greaterThanOne');
    }

    get nonNegativeNumberValidationMessage() {
        return this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.messages.saveEdit.validation.number.nonNegative');
    }

    get bankAccount() {
        return this.model.bankAccount;
    }

    get hasDirtyAttributes() {
        return this.bankAccount.hasDirtyAttributes;
    }

    @action
    updateAccountNumber(value) {
      set(this, 'accountNumber', value);
      set(this, 'bankAccount.accountNumberLastFour', value === this.fetchedAccountNumber ? value.slice(-4) : value);
    }

    @action
    updateRoutingNumber(value) {
      set(this, 'routingNumber', value);
      set(this, 'bankAccount.routingNumberLastFour', value === this.fetchedRoutingNumber ? value.slice(-4) : value);
    }

    @action
    destroyAccountNumber(value) {
      set(this, 'accountNumber', null);
      set(this, 'fetchedAccountNumber', null);
      if(!this.bankAccount.isDestroying) set(this, 'bankAccount.accountNumberLastFour', value);
    }

    @action
    destroyRoutingNumber(value) {
      set(this, 'routingNumber', null);
      set(this, 'fetchedRoutingNumber', null);
      if (!this.bankAccount.isDestroying) set(this, 'bankAccount.routingNumberLastFour', value);
    }

    @action
    async unmaskAccountNumber() {
      if(!this.accountNumber){
        const accountNumber = await this.api.json.get(`tribute_pay/v1/bank_accounts/${this.bankAccount.id}/unmask_account_details`, { params: { identifier_type: 'account_number'} });
        set(this, 'fetchedAccountNumber', accountNumber?.parsedJson?.account_number);
        set(this, 'accountNumber', accountNumber?.parsedJson?.account_number);
      }
    }

    @action
    async unmaskRoutingNumber() {
      if(!this.routingNumber){
        const routingNumber = await this.api.json.get(`tribute_pay/v1/bank_accounts/${this.bankAccount.id}/unmask_account_details`, { params: { identifier_type: 'routing_number'} });
        set(this, 'fetchedRoutingNumber', routingNumber?.parsedJson?.routing_number);
        set(this, 'routingNumber', routingNumber?.parsedJson?.routing_number);
      }
    }

    @action
    changeGLAccount(GLAccountId) {
        set(this, 'bankAccount.generalLedgerAccountId', GLAccountId);
        const GLAccount = GLAccountId ? this.model.accounts.find(account => account.id === GLAccountId) : null;
        set(this, 'bankAccount.generalLedgerAccountName', GLAccount ? GLAccount.name : null);
    }

    @action
    async saveBankAccount() {
        const { validations } = await this.validate();
        if (validations.isValid) {
            set(this, 'showValidations', false);
            
            if (this.hasDirtyAttributes) {
                try {
                    if(this.bankAccount.changedAttributes()['bankAccountStaffAssignments']){
                    const requestBody = {
                        bank_account_id: this.bankAccount.id,
                        bank_account_staff_assignments: this.bankAccount.bankAccountStaffAssignments.map(({ id, external_staff_uuid }) => ({id, external_staff_uuid }) )};
                    await this.api.json.post('tribute_pay/v1/bank_accounts/add_remove_staff_members',{body:requestBody});}
                    await this.bankAccount.save();
                    this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.messages.saveEdit.success'));
                    this.router.transitionTo('authenticated.admin.financial-configurations.edit.bank-accounts');
                } catch {
                    this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.messages.saveEdit.error'));
                }
            }
        } else {
            set(this, 'showValidations', true);
        }
    }

    @action
    async searchStaff(query) {
        const existingStaffIds = this.bankAccount.bankAccountStaffAssignments.map(staff => staff.external_staff_uuid);
        const allUsers = await this.store.query('user', {
            archived: false,
            per_page: null,
            sort_by: null,
            order: 'ASC',
            client_id: this.session.currentClient.id,
            query 
        });
        return allUsers.filter(user => !existingStaffIds.includes(user.uuid));
    }

    @action
    cancelStaffSearch(){
        set(this, 'showStaffMemberSearch', false);
        set(this, 'selectedStaff', '');
    }

    @action
    addStaff(){
        try {
            const staff = {
                external_staff_uuid: this.selectedStaff.uuid,
                bank_account_id: this.bankAccount.id,
                first_name: this.selectedStaff.firstName,
                last_name: this.selectedStaff.lastName,
                email: this.selectedStaff.email
            }
            set(this, 'bankAccount.bankAccountStaffAssignments', [...this.bankAccount.bankAccountStaffAssignments,staff]);
            set(this, 'selectedStaff', '');
            this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.sections.staffMemberPermissions.messages.addStaff.success'));
        } catch {
            this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.sections.staffMemberPermissions.messages.addStaff.error'));
        }
    }

    @action
    removeStaff(staff) {
        try {
            set(this, 'bankAccount.bankAccountStaffAssignments', [...this.bankAccount.bankAccountStaffAssignments.removeObject(staff)]);
            this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.sections.staffMemberPermissions.messages.removeStaff.success'));
        } catch {
            this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.edit.sections.staffMemberPermissions.messages.removeStaff.error'));
        }
    }

    @action
    scrollToTop() {
        window.scrollTo(0, 0);
    }

    @action 
    toggleCheckSettings() {
        if (this.bankAccount.startingCheckNumber <= 0) set(this, 'bankAccount.startingCheckNumber', '');
        if (!this.bankAccount.dateRange || this.bankAccount.dateRange < 0) set(this, 'bankAccount.dateRange', '90');
    }
}

