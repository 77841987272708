import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage');

    return RSVP.hash({
      model,
      kase: model,
      bassMollettExport: this.store.queryRecord('integrationExport', {
        kase_id: get(model, 'id'),
        type: 'bass_mollett',
        most_recent_export: true,
        status: 'successful'
      })
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model, transition);
    set(
      controller,
      'events',
      this.store.query('event', { kase_id: get(model, 'kase.id') })
    );
  },

  actions: {
    bassMollett(e) {
      e.target.disabled = true; // avoid double-click issues
      const kaseId = this.currentModel.kase.id;

      this.api.json.put(
        'bass_mollett/v2/kases',
        { body: { id: kaseId } }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase', response.parsedJson);
          this.send(
            'flashSuccess',
            'You successfully exported to Bass-Mollett.'
          );
          this.refresh();
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    },

    avatar(e) {
      e.target.disabled = true; // avoid double-click issues
      const kaseId = this.currentModel.kase.id;

      this.api.json.put(
        'bass_mollett/v2/avatar',
        { body: { id: kaseId } }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase.avatar', response.parsedJson);
          this.send('flashSuccess', 'Profile Picture successfully exported!'); // Not the best validation, but will fix once rebase with integration_export updates
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    }
  }
});
