import RSVP from 'rsvp';
import Route from '@ember/routing/route';
import { set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminGeneralPriceListsEditProductsEditRoute extends Route {
  @service api;
  @service store;
  @service session;
  @service productCategories;

  async model(params) {
    const product = this.store.findRecord('product', params.product_id);
    const costChanges = await this.store.query('costChange', { product_id: params.product_id });
    const priceChanges = await this.store.query('priceChange', { product_id: params.product_id });

    return RSVP.hash({
      product,
      costChanges,
      priceChanges
    });
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const gpl = this.modelFor('authenticated.admin.general-price-lists.edit');

    controller.setProperties({
      generalPriceList: model.product.get('generalPriceList'),
      packages: this.store.query('package', {
        product_id: model.product.get('id'),
        archived: false
      }),
      productCategories: this.productCategories.getProductCategories(gpl.id),
      product: model.product,
    });

    if (model.product.get('tracking') == false) {
      set(this.controller, 'showInventory', false);
    }
  }

  afterModel(model) {
    model.product.saveRelationshipState();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('additionalInfo', false);
    }
  }
}
