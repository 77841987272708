import Model, { attr, belongsTo, hasMany } from '@ember-data/model';
import { inject as service } from '@ember/service';

const defaultRemoteDocumentParams = () => {
  return {
    eventId: null,
    inquiryId: null,
    kaseContactId: null,
    locationId: null
  }
}

export default class TfeGeneratedDocumentModel extends Model {
  @service intl;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') createdAt;
  @attr('moment-datetime') signingCompletedAt;
  @attr('moment-datetime') signingInitializedAt;
  @attr('moment-datetime') updatedAt;

  @attr('object', { defaultValue: () => defaultRemoteDocumentParams() }) remoteDocumentParams;

  @attr('string') name;
  @attr('string') remoteId;
  @attr('string') signingStatus;
  @attr('string') status;

  @attr('boolean') customUpload;
  @attr('boolean') documentHasPreviousEsignRequests;
  @attr('boolean') documentHasPreviousVersions;
  @attr('boolean') esignEnabled;
  @attr('boolean') hasEsignableRoles;
  @attr('boolean') inPersonSigning;

  @belongsTo('tfe/document', { async: true }) document;
  @belongsTo('tfe/revision', { async: true }) revision;

  @hasMany('tfe/signer', { async: false }) signers;

  get hasSentEsignRequests() {
    return ['pending', 'completed'].includes(this.signingStatus);
  }

  get formattedSigningStatus() {
    switch(this.signingStatus) {
      case 'not_applicable':
        return this.intl.t('model.tfe.generated-document.signingStatus.notApplicable');
      case 'awaiting_initialization':
        return this.intl.t('model.tfe.generated-document.signingStatus.awaitingInitialization');
      case 'pending':
        return this.intl.t('model.tfe.generated-document.signingStatus.pending');
      case 'completed':
        return this.intl.t('model.tfe.generated-document.signingStatus.completed');
      case 'canceled':
        return this.intl.t('model.tfe.generated-document.signingStatus.canceled');
      default:
        return this.intl.t('model.tfe.generated-document.signingStatus.notApplicable');
    }
  }
}
