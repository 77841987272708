import { set, get } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default Route.extend({
  api: service(),
  layout: 'custom-aside',

  model() {
    const model = this.modelFor('authenticated.kases.manage');

    return RSVP.hash({
      model,
      kase: model,
      cfsExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'cfs',
        most_recent_export: true,
        status: 'successful'
      })
    });
  },

  setupController(controller, model, transition) {
    this._super(controller, model, transition);
    set(controller, 'kase', model.kase);
    set(controller, 'cfsExport', model.cfsExport);
    if (get(model, 'cfsExport')) {
      set(controller, 'websiteName', get(model, 'cfsExport.websiteName'));
    } else {
      set(controller, 'websiteName', '');
    }
    set(
      controller,
      'events',
      this.store.query('event', { kase_id: get(model, 'kase.id') })
    );
  },

  actions: {
    cfs(e) {
      e.target.disabled = true; // avoid double-click issues
      const kaseId = this.currentModel.kase.id;
      const exportId = this.controller.get('cfsExport.id') || null;
      const websiteName = this.controller.get('websiteName');

      this.api.json.put(
        'cfs/kases',
        { 
          params: { export_id: exportId },
          body: { id: kaseId, website_name: websiteName }
        }
      ).then(response => {
        if (response.ok) {
          this.refresh();
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    },

    avatar(e) {
      e.target.disabled = true; // avoid double-click issues
      const kaseId = this.currentModel.kase.id;
      const exportId = this.controller.cfsExport.id || null;
      const websiteName = this.controller.get('websiteName');

      this.api.json.put(
        'cfs/avatar',
        { 
          params: { export_id: exportId },
          body: { id: kaseId, website_name: websiteName }
        }
      ).then(response => {
        if (response.ok) {
          this.store.pushPayload('kase.avatar', response.parsedJson);
          this.send('flashSuccess', 'Profile Picture successfully exported!'); // Not the best validation, but will fix once rebase with integration_export updates
          e.target.disabled = false; // avoid double-click issues
        } else {
          this.send(
            'flashError',
            'Oops, something went wrong! Please try again.'
          );
          e.target.disabled = false; // avoid double-click issues
        }
      });
    }
  }
});
