import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class AuthenticatedChecksCheckDetailsAuditLogRoute extends SearchRoute {
    @service session;
    @service router;

    model(params) {
        return this.store.query('v2/audit-log', {
            reference_id: this.modelFor('authenticated.checks.check-details').id,
            page: params.page,
            per_page: 10
        });
    }

    beforeModel() {
        if (!(this.session.currentClient.can_view_financial_configurations && this.session.currentClient.can_view_financial_config_bank_accounts && this.session.currentClient.can_view_check_writing && this.session.currentUser.isSuperuser)){
            this.router.transitionTo('authenticated.dashboard');
        }
    }
    
    resetController(controller, isExiting) {
        if (isExiting) {
            controller.setProperties({
                page: 1,
            });
        }  
    } 
}
