import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCraknClientsEditSfgsConfigEditLocationAccessController extends Controller {
  @tracked isPopoutVisible = false;

  @action
  togglePopout() {
    this.isPopoutVisible = !this.isPopoutVisible;
  }
}
