import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AccountingExportsSelectionExporter extends Component {
  @service store;

  @tracked chartOfAccountsId = null;
  @tracked model = null;

  @action
  export() {
    this.send('export');
  }

  @action
  addSelection(selection) {
    this.send('addSelection', selection);
  }

  @action
  removeSelection(selection) {
    this.send('removeSelection', selection);
  }
}
