import { set } from '@ember/object';
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedChecksCheckDetailsEditRoute extends Route {
  @service session;
  @service router;

  async model() {
    const check = this.modelFor('authenticated.checks.check-details');
    const bankAccounts = await this.store.query('tribute-pay/v1/bank-account', {
      is_check_settings_enabled: check.status=='draft'||undefined,
      per_page: null
    });
    const bankAccount = bankAccounts.find((account) => account.id === check.bankAccountId);
    if (!bankAccount) set(check, 'bankAccountId', null);
    let payee;
    if(check.payeeId){
      if(check.payeeType == 'vendor') payee = await this.store.findRecord('v2/place', check.payeeId);
      else if(check.payeeType == 'contact') payee = await this.store.findRecord('v2/person', check.payeeId);
    }
    else payee = null;

    return { check, bankAccounts, bankAccount, payee };
  }

  beforeModel() {
    if (!(this.session.currentClient.can_view_financial_configurations && this.session.currentClient.can_view_financial_config_bank_accounts && this.session.currentClient.can_view_check_writing && this.session.currentUser.hasCheckAccess)){
        this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.setProperties({
      checkDate: model.check.date,
      checkNumber: model.check.number,
      checkStatus: model.check.status,
      dateRange: model.bankAccount ? model.bankAccount.dateRange ?? 90 : 90,
      newPayee: model.payee,
      payeeId: model.payee? model.payee.id: '',
      showValidations: this.paramsFor(this.routeName).action ? true : false
    });
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        showValidations: false,
        newPayee: null,
        dateRange: 90,
        showVoidConfirmDilog: false,
        isPrintingCheck: false
      });
      controller.model.check.rollbackAttributes();

    }
  }
}
