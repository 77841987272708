import Model, { attr, belongsTo } from '@ember-data/model';

export default class AuditLogModel extends Model {
   @attr('string') message;
   @attr('moment-datetime') createdAt;
   @attr('string') internalMessage;
   @attr('string') referenceId;
   @attr('string') referenceType;

   @belongsTo('v2/user', { async: true }) user;
}
