import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TableRowGlAccountComponent extends Component {
  @service flashes;
  @service intl;

  get account() {
    return this.args.account;
  }

  get deleteDialogPrompt() {
    const archiveDialog = this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.archiveDialog');
    return `${archiveDialog} ${this.account.name}?`;
  }

  get archiveText() {
    return this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.archive');
  }

  @action
  toggleArchive() {
    this.account.destroyRecord().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.messages.archive.success'));
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.messages.archive.error'));
    });
    this.args.refreshModel();
  }
}
