import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedYellowReportController extends Controller {
  @tracked organization_location_id = null;
  queryParams = ['organization_location_id'];

  perPage = null;
  kaseTaskReportPage = 1;

  @action
  loadMoreKaseTaskReports() {
    this.kaseTaskReportPage += 1;
    this.store
      .query('kaseTaskReport', {
        organization_location_id: this.organization_location_id,
        per_page: this.perPage,
        page: this.kaseTaskReportPage
      })
      .then(results => {
        this.model.content.pushObjects(
          results.content
        );
      });
  }

  printedDate = new Date();
}
