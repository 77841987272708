import Controller from '@ember/controller';
import { alias, filterBy } from '@ember/object/computed';
import Search from 'crakn/mixins/search';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminFinancialConfigurationsEditTaxItemsController extends Controller.extend(Search) {
  @service taxVerbiage;

  @tracked taxItems = alias('model');
  @tracked filteredTaxItems = filterBy('taxItems', 'archivedAt', null);
  @tracked chartOfAccounts = null;
}
