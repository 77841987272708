import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<G::Crakn::Field::Select\n    @dataTest={{@dataTest}}\n    @disabled={{@disabled}}\n    @onChange={{this.selectBankAccount}}\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{@placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    @value={{@value}}\n    class=\"c--xs--12\">\n  {{#each @options as |bankAccount|}}\n    <option\n        value={{bankAccount.id}}\n        selected={{eq bankAccount.id @value}}>\n      {{bankAccount.bankAccountName}} (...{{bankAccount.accountNumberLastFour}})\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n", {"contents":"<G::Crakn::Field::Select\n    @dataTest={{@dataTest}}\n    @disabled={{@disabled}}\n    @onChange={{this.selectBankAccount}}\n    @placeholder={{@placeholder}}\n    @placeholderLabel={{@placeholderLabel}}\n    @placeholderValue={{@placeholderValue}}\n    @showValidations={{@showValidations}}\n    @validationMessages={{@validationMessages}}\n    @value={{@value}}\n    class=\"c--xs--12\">\n  {{#each @options as |bankAccount|}}\n    <option\n        value={{bankAccount.id}}\n        selected={{eq bankAccount.id @value}}>\n      {{bankAccount.bankAccountName}} (...{{bankAccount.accountNumberLastFour}})\n    </option>\n  {{/each}}\n</G::Crakn::Field::Select>\n","moduleName":"crakn/components/g/crakn/selector/bank-account.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/selector/bank-account.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

export default class GCraknSelectorBankAccount extends Component {
  @action
  selectBankAccount(bankAccountId) {
    const selectedBankAccount = this.args.options.findBy('id', bankAccountId);
    this.args.onChange(selectedBankAccount);
  }
}
