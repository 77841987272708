import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import Bugsnag from '@bugsnag/js';
import config from 'crakn/config/environment';

export default class AuthenticatedRoute extends Route {
  @service intl;
  @service router;
  @service session;
  @service tmsSettings;

  constructor() {
    super(...arguments);
    this.router.on('routeDidChange', (transition) => {
      if (transition?.to?.name?.includes('authenticated.kases.manage') || transition?.to?.name?.includes('authenticated.kases.overview') ){
        this.tmsSettings.loadSettings();
      }
    })
  }

  beforeModel(transition) {
    const country = this.session.currentClient?.operating_country || 'US';
    const locale = this.getLocaleFromCountry(country) || this.session.browserLocale || 'en-us';
    this.intl.setLocale([locale]);
    this.session.requireAuthentication(transition, 'login');
  }

  getLocaleFromCountry(country) {
    const countryToLocaleMap = {
      US: 'en-us',
      CA: 'en-ca'
    };

    return countryToLocaleMap[country];
  }

  @action
  async didTransition() {
    if (!this.session.currentUser.agreedToTermsOfUse ||
               !this.session.currentUser.agreedToPrivacy) {
      this.transitionTo('authenticated.agree-to-legal');
    } else if (this.session.isAuthenticated) {
      this.setupIntercom();
      this.setupBugsnag();
    }
  }

  setupIntercom() {
    if (window.Intercom) {
      window.Intercom('update');
    }
  }

  setupBugsnag() {
    if (config.environment === 'production') {
      Bugsnag.addMetadata('user', {
        user_id: this.session.currentUser.id,
        user_email: this.session.currentUser.email,
        client_id: this.session.currentClient.id,
        client_name: this.session.currentClient.name
      });
    }
  }
}
