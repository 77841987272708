import Route from '@ember/routing/route';
import RSVP from 'rsvp';

export default class CemsitesRoute extends Route {
  layout = 'custom-aside';

  model() {
    const model = this.modelFor('authenticated.kases.manage');

    return RSVP.hash({
      model,
      kase: model,
      cemsitesExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'cemsites',
        most_recent_export: true
      })
    });
  }
}
