import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { later } from '@ember/runloop';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedChecksCheckDetailsController extends Controller {

  @service api;
  @service flashes;
  @service intl;
  @service router;
  @service store;

  pollingAttempts = 10;
  pollingDelay = 3000;
  @tracked isDownloading;


  get updatedCheckNumber() {
    return this.model.hasDirtyAttributes ? this.checkNumber : this.model.number;
  }

  @action
  async checkIfGenerationCompleted() {
    set(this, 'isDownloading', true);
    try {
      const response = await this.store.findRecord('tfe/generatedDocument', this.model.generatedDocumentId, { reload: true });
      this.pollingAttempts--;

      if (this.pollingAttempts > 0 && ['processing', 'pending'].includes(response.status)) {
        return this.pollForCheck();
      }

      if (response.status === 'completed') {
        set(this, 'retryDownload', false);
      } else {
        set(this, 'retryDownload', true);
        this.flashes.addError(this.intl.t('authenticated.checks.check-details.messages.printError'));
      }

      set(this, 'isDownloading', false);
      set(this, 'pollingAttempts', 10);
      set(this, 'pollingDelay', 3000);

    } catch (error) {
      set(this, 'isDownloading', false);
      set(this, 'pollingAttempts', 10);
      set(this, 'pollingDelay', 3000);
      set(this, 'retryDownload', true);
      this.flashes.addError(this.intl.t('authenticated.checks.check-details.messages.printError'));
    }
  }
    
  pollForCheck() {
    if (this.isDestroying || this.isDestroyed) return;
    this.pollingDelay += 500;
    this.checkPoller = later(this, this.checkIfGenerationCompleted, this.pollingDelay);
  }

  @action
  async downloadCheck() {
    try {
      if (this.retryDownload) {
        set(this, 'isDownloading', true);
        await this.checkIfGenerationCompleted();
        if (this.retryDownload) return;
      }

      const response = await this.api.get(`tfe/generated_documents/${this.model.generatedDocumentId}/download`, { params: { check_id: this.model.id } });
      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = this.intl.t('authenticated.checks.check-details.downloadedCheckName', { bankAccountName: this.model.bankAccount.bankAccountName, checkNumber: this.model.number });
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        this.flashes.addError(this.intl.t('authenticated.checks.check-details.messages.downloadError'));
      }
    } catch (error) {
      this.flashes.addError(this.intl.t('authenticated.checks.check-details.messages.downloadError'));
    }
  }
}
