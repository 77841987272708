import Controller from '@ember/controller';
import { action, computed, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { debounce } from '@ember/runloop';

export default class AdminUsersEditLocationAccessController extends Controller {
  queryParams = ['page', 'organization_id', 'user_name'];

  @service flashes;
  @service intl;
  @service store;

  @tracked isEnabling = false;
  @tracked isLoadingUnassignedUsers = false;
  @tracked isPopoutVisible = false;
  @tracked location;
  @tracked locationId;
  @tracked organization_id = null;
  @tracked pages;
  @tracked popout_organization_id = null;
  @tracked popout_user_name = '';
  @tracked selectAllChecked = false;
  @tracked temp_popout_user_name = '';
  @tracked temp_user_name = '';
  @tracked totalUnassignedUsersCount = 0;
  @tracked unassignedUsers = [];
  @tracked user_name = '';
  @tracked userCount = 0;
  @tracked userLocations = [];
  @tracked userPage = 1;

  @computed('userPage', 'pages')
  get hasUsersLeft() {
    return this.pages > 0 && this.userPage !== this.pages;
  }

  get noSearchResults() {
    return this.unassignedUsers.length === 0 && this.popout_user_name !== '';
  }

  get selectAllState() {
    const selectedCount = this.userLocations.length;
    const totalCount = this.unassignedUsers.length;

    if (selectedCount === 0) {
      return false; // Unchecked
    } else if (selectedCount === totalCount) {
      return true; // Checked
    } else {
      return 'indeterminate'; // Some checked
    }
  }

  @action
  toggleSelectAll(event) {
    this.selectAllChecked = event.target.checked;

    if (this.selectAllChecked) {
      set(this, 'userLocations', this.unassignedUsers.toArray());
      set(this, 'userCount', this.totalUnassignedUsersCount);
    } else {
      set(this, 'userLocations', []);
      set(this, 'userCount', 0);
    }
  }

  _setPopoutUserName(value) {
    set(this, 'popout_user_name', value);
  }

  @action
  openPopout() {
    this.isPopoutVisible = true;
  }

  @action
  setPopoutUserName(value) {
    this.temp_popout_user_name = value;

    debounce(this, this._setPopoutUserName, this.temp_popout_user_name, 300);
  }

  @action
  setUserName(value) {
    this.temp_user_name = value;

    debounce(this, this._setUserName, value, 400);
  }

  @action
  _setUserName(value) {
    this.user_name = value;
  }

  @action
  clearSearch() {
    set(this, 'popout_user_name', '');
    set(this, 'temp_popout_user_name', '');
    this.getUnassignedUsers();
  }

  @action
  closePopout() {
    set(this, 'isPopoutVisible', false);
    set(this, 'userPage', 1);
    set(this, 'userLocations', []);
    set(this, 'popout_user_name', '');
    set(this, 'temp_popout_user_name', '');
    set(this, 'popout_organization_id', null);
    set(this, 'selectAllChecked', false);
    set(this, 'userCount', 0);
    this.getUnassignedUsers();
  }

  @action
  toggleUser(userId) {
    if (this.userExists(userId)) {
      set(this, 'userLocations', this.userLocations.filter((user) => user.id !== userId));
      if (this.userCount > 0) {
        set(this, 'userCount', this.userCount - 1);
      }
    } else {
      set(this, 'userLocations', [...this.userLocations, this.unassignedUsers.find((user) => user.id === userId)]);
      set(this, 'userCount', this.userCount + 1);
    }
  }

  @action
  userExists(userId) {
    return this.userLocations.some((user) => user.id === userId);
  }

  @action
  removeUserLocation(userLocation) {
    userLocation.destroyRecord().then(() => {
      this.flashes.addSuccess(
        this.intl.t('authenticated.admin.users.edit.location-access.actions.remove.success')
      );
      this.send('refreshModel');
    })
    .catch(() => {
      this.flashes.addError(
        this.intl.t('authenticated.admin.users.edit.location-access.actions.remove.error')
      );
    });
  }

  // This is called by the route's model hook
  async getUnassignedUsers() {
    try {
      set(this, 'isLoadingUnassignedUsers', true);

      const users = await this.store.query('v2/user', {
        per_page: 10,
        not_assigned_to_location_id: this.locationId,
        archived: false,
        user_name: this.popout_user_name
      });

      set(this, 'unassignedUsers', users);
      set(this, 'pages', users.meta.pages);
      set(this, 'totalUnassignedUsersCount', users.meta.total);
    } catch (error) {
      console.error('Failed to load unassigned users', error);
    } finally {
      set(this, 'isLoadingUnassignedUsers', false);
    }
  }

  async loadAllUnassignedUsers() {
    const results = await this.store.query('v2/user', {
      per_page: 1000,
      archived: false,
      not_assigned_to_location_id: this.locationId,
      user_name: this.popout_user_name
    });

    const existingIds = new Set(this.unassignedUsers.map(user => user.id));
    const newUsers = results.content.filter(user => !existingIds.has(user.id));

    set(this, 'userLocations', [...this.userLocations, ...newUsers]);
  }

  @action
  loadMoreUsers() {
    if (this.userPage < this.pages) {
      set(this, 'userPage', this.userPage + 1);
      const page = this.userPage;
      const locationId = this.locationId;

      this.store
        .query('v2/user', {
          per_page: 10,
          not_assigned_to_location_id: locationId,
          archived: false,
          page
        })
        .then(results => {
          if (this.selectAllChecked) {
            this.userLocations.pushObjects(results.content);
          }
          this.unassignedUsers.content.pushObjects(results.content);
        });
    }
  }

  @action
  async enableUsers() {
    this.isEnabling = true;
    if (this.selectAllChecked) {
      await this.loadAllUnassignedUsers();
    }

    const userPromises = this.userLocations.map(async (user) => {
      const existingUserLocation = (await this.store.query('v2/userLocation', {
        user_id: user.id,
        location_id: this.locationId,
        archived: true
      })).firstObject;

      if (existingUserLocation && existingUserLocation.id) {
        existingUserLocation.set('archivedAt', null);
        return existingUserLocation.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.success')
          );
          this.send('refreshModel');
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.error')
          );
        });
      } else {
        const userLocation = this.store.createRecord('v2/userLocation', {
          user,
          location: this.location,
        });

        return userLocation.save().then(() => {
          this.flashes.addSuccess(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.success')
          );
          this.send('refreshModel');
        })
        .catch(() => {
          this.flashes.addError(
            this.intl.t('authenticated.admin.users.edit.location-access.actions.access.error')
          );
        });
      }
    });

    await Promise.all(userPromises);
    this.isEnabling = false;
    this.closePopout();
  }
}
