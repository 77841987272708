import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class KasesIntegrationController extends Controller {
  @service router;

  @tracked sortedIntegrationServices = null;

  get isTributeWebsiteRouteActive() {
    return this.router.currentRouteName === 'authenticated.kases.manage.integrations.tribute-website';
  }
}
