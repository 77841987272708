import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { cancel } from '@ember/runloop';

export default class AuthenticatedChecksCheckDetailsRoute extends Route {
  @service router;
  @service session;
  @service store;

  model(params) {
    return this.store.findRecord('tribute-pay/v1/check', params.check_id, { reload : true });
  }

  beforeModel() {
    if (!(this.session.currentClient.can_view_financial_configurations && this.session.currentClient.can_view_financial_config_bank_accounts && this.session.currentClient.can_view_check_writing && this.session.currentUser.hasCheckAccess)){
        this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model) {
    super.setupController(controller, model);
    controller.set('checkNumber', model.get('number'));
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);
    if (isExiting) {
      controller.setProperties({
        isDownloading: true,
        pollingAttempts: 10,
        pollingDelay: 3000,
        retryDownload: false
      });
      cancel(controller.checkPoller);
    }
  }
}
