import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<tr>\n  <td class=\"strong data-height td--white td--white-border-bottom\">\n    {{this.description}}\n  </td>\n  <td class=\"strong data-height td--white td--white-border-bottom\">\n    {{this.glAccount}} {{#if this.accountNumber}} ({{this.accountNumber}}) {{/if}}\n  </td>\n  <td class=\"strong data-height td--white td--white-border-bottom\">\n    {{@qbdClassName}}\n  </td>\n  <td class=\"data-height td--white td--white-border-bottom td-right\">\n    {{show-price this.amount}}\n  </td>\n</tr>\n", {"contents":"<tr>\n  <td class=\"strong data-height td--white td--white-border-bottom\">\n    {{this.description}}\n  </td>\n  <td class=\"strong data-height td--white td--white-border-bottom\">\n    {{this.glAccount}} {{#if this.accountNumber}} ({{this.accountNumber}}) {{/if}}\n  </td>\n  <td class=\"strong data-height td--white td--white-border-bottom\">\n    {{@qbdClassName}}\n  </td>\n  <td class=\"data-height td--white td--white-border-bottom td-right\">\n    {{show-price this.amount}}\n  </td>\n</tr>\n","moduleName":"crakn/components/g/accounting-exports/item-breakdown-table-row.hbs","parseOptions":{"srcName":"crakn/components/g/accounting-exports/item-breakdown-table-row.hbs"}});
import Component from '@glimmer/component';

export default class GAccountingExportsItemBreakdownTableRow extends Component {
  get description() {
    const item = this.args.model;
    switch (item.class) {
      case 'Payment':
        return item.payment_type_name || this.extractDescription(item.description);
      case 'Discount':
        return item.discount_type_name || this.extractDescription(item.description);
      case 'Refund':
        return 'Overpayment Refund';
      case 'Adjustment':
        return this.extractAdjustmentDescription(item)
      default:
        return item.name;
    }
  }

  get glAccount() {
    return this.args.model.account_name;
  }

  get accountNumber() {
    const model = this.args.model;
    return model.account_general_ledger_id || model.account_remote_id;
  }

  get amount() {
    const item = this.args.model;
    switch (item.class) {
      case 'SelectionPackage':
        return item.total || this.extractAmountFromDescription(item.description);
      case 'Payment':
      case 'Discount':
      case 'Refund':
      case 'Adjustment':
        return this.extractAdjustmentAmount(item);
      default:
        return item.original_price;
    }
  }

  extractDescription(description) {
    const match = description.match(/^(.*?)\s*\(/);
    return match ? match[1] : description;
  }

  extractAmountFromDescription(description) {
    const match = description.match(/\(\$(\d+(\.\d{2})?)\)/);
    return match ? match[1] : '';
  }

  extractAdjustmentAmount(item) {
    if (item.is_tax_adjustment) {
      return this.extractAmountFromLineItemDescription(item);
    } else {
      return item.amount;
    }
  }

  extractAmountFromLineItemDescription(item) {
    if (item.is_tax_adjustment) {
      if (item?.sales_tax?.total_tax) {
        return item.sales_tax.total_tax;
      } else {
        return item.amount;
      }
    }
  }

  extractAdjustmentDescription(item) {
    if (item.is_tax_adjustment) {
      if (item?.sales_tax?.total_tax) {
        return `${item.name} - Tax Adjustment`;
      }
    } else {
      return item.name;
    }
  }
}
