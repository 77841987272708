import Component from '@glimmer/component';
import moment from 'moment';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AltmeyerCaseCountByClientTypeReport extends Component {
  @tracked startsAt = moment().subtract(30, 'day').toISOString();
  @tracked endsAt = moment().toISOString();
  @tracked organizationLocationId = null;

  get reportLink() {
    const params = new URLSearchParams({
      organization_location_id: this.organizationLocationId,
      starts_at: this.startsAt,
      ends_at: this.endsAt,
      location_id: this.location_id,
      uuid: this.args.uuid
    }).toString();
    return `/report/altmeyer_case_count_by_client_type_report?${params}`;
  }

  get fileUrl() {
    return `#/generated_document/${this.args.uuid}`;
  }

  @action
  setDate(attr, value) {
    set(this, attr, value);
  }

  @action
  generateReport() {
    this.args.onGenerateReport(this.reportLink);
  }
}
