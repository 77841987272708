import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsNewRoute extends Route {
  @service router;
  @service session;

  model() {
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
    const accounts = this.store.query('v2/account', {
      chart_of_accounts_id: coa.get('id'),
      archived: false,
      per_page: null  
    });
    return { coa, accounts }
  }

  beforeModel() {
    if (
      !this.session.currentClient.can_view_financial_configurations ||
      !this.session.currentClient.can_view_financial_config_bank_accounts
    ) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        accountNumberLastFour: null,
        bankAccountName: null,
        bankName: null,
        generalLedgerAccountId: null,
        generalLedgerAccountName: null,
        routingNumberLastFour: null,
        showValidations:false
      });
    }
  }
}
