import SearchController from 'crakn/controllers/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCraknCheckTemplatesIndexController extends SearchController {
  @service api;
  @service flashes;
  @service intl;

  @tracked isCheckTemplatesEmpty;

  get zeroCheckTemplatesMessage() {
    const zeroCheckTemplatesMatchingSearchMessage = this.intl.t('authenticated.crakn.manage.check-templates.index.messages.noCheckTemplatesMatchingSearch');
    const zeroCheckTemplatesAddedMessage = this.intl.t('authenticated.crakn.manage.check-templates.index.messages.noCheckTemplates');

    return this.isCheckTemplatesEmpty
      ? (this.search
        ? zeroCheckTemplatesMatchingSearchMessage
        : zeroCheckTemplatesAddedMessage)
      : zeroCheckTemplatesMatchingSearchMessage;
  }
  
  @action
  async downloadTest(checkTemplate) {
    const button = event.target;
    button.disabled = true;

    try {
      const response = await this.api.get(`tfe/documents/${checkTemplate.id}/download`);

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `${checkTemplate.name}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        this.flashes.addError(this.intl.t('authenticated.crakn.manage.check-templates.index.messages.downloadTemplateError'));
      }
    } catch (error) {
      this.flashes.addError(this.intl.t('authenticated.crakn.manage.check-templates.index.messages.downloadTemplateError'));
    } finally {
      button.disabled = false;
    }
  }
}
