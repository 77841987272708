import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknCheckTemplatesEditRoute extends Route {
  @service store;

  async model(params) {
    const checkTemplate = await this.store.findRecord('tfe/document', params.template_id, { include: 'latest_revision', reload: true });
    const enabledDocumentClients = this.store.query('tfe/client', { document_id: params.template_id, per_page: 0 })
    return { checkTemplate, enabledDocumentClients }
  }

  async setupController(controller, model, _transition) {
    super.setupController(...arguments);

    controller.setProperties({
      documentFile: null,
      enabledDocumentClients: model.enabledDocumentClients,
      checkTemplate: model.checkTemplate,
    });
  }

  resetController(controller, isExiting) {
    super.resetController(...arguments);

    if (isExiting) {
      controller.setProperties({
        documentFile: null,
        enabledDocumentClients: null,
        checkTemplate: null,
        showValidation: false
      });
    }
  }

  @action 
  willTransition(_transition) {
    if (this.currentModel.checkTemplate.hasDirtyAttributes) {
      this.currentModel.checkTemplate.rollbackAttributes();
    }
  }
}
