import Controller from '@ember/controller'
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AdminLocationsEditController extends Controller {
  @service session;
  @service flashes;

  @tracked clientInfo;

  @action
  save(event, options) {
    event.preventDefault();

    if (event.submitter) {
      event.submitter.disabled = true;
    }

    if (!options) {
      options = {
        adapterOptions: {
          include: [
            'address,vehicles,general-price-list,general-price-list.chart-of-accounts,special-taxes',
            'client.contract-templates', 'client.soa-templates', 'default-receipt-logo', 'default-sfgs-logo', 'default-soa-logo', 'managing-director'
          ].join(',')
        }
      }
    }

    this.model
      .save(options)
      .then(() => {
        this.flashes.addSuccess('Location successfully saved!');
      })
      .catch(() => {
        this.flashes.addError('There was an error saving.');
      });

    if (event.submitter) {
      event.submitter.disabled = false;
    }

    return false
  }
}
