import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { inject as controller } from '@ember/controller';


export default class AuthenticatedKasesManageIntegrationsIndexController extends Controller {
  @controller('authenticated.kases.manage.integrations') integrationsController;
  @service tmsSettings

  @tracked tributeWebsiteKase; // Set up in the route.
  @tracked tributeVideoExport; // Set up in the route.
  @tracked tributeWebsiteKaseExported = null;
  @tracked hasPageLoadedBefore = false;

  get tributeVideoStatus() {
    const status = this.tributeVideoExport.get('tributeVideoStatus');

    return status || 'Please go to the integration page and update the status.';
  }

  get sortedIntegrationServices() {
    return this.integrationsController.sortedIntegrationServices;
  }

  /**
   * If this is updated, also update the fetchIntegrationServices in the following file:
   * app/routes/authenticated/kases/manage/integrations.js
   */
  @action
  fetchIntegrationServices() {
    if (!this.hasPageLoadedBefore) {
      set(this, 'hasPageLoadedBefore', true);
      return;
    }

    this.store.query('integrationService', {
      kase_id: this.kaseId,
      not_category: ['accounting', 'answering_service'],
      per_page: 100,
      status: 1 // successful 
    });
  }
}
