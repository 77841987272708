import { inject as service } from '@ember/service';
import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedAdminFinancialConfigurationsEditBankAccountsRoute extends SearchRoute {
  @service router;
  @service session;
  @service store;
  model(params) {
    const coa = this.modelFor('authenticated.admin.financial-configurations.edit');
    return this.store.query('tribute-pay/v1/bank-account', { 
      chart_of_accounts_id: coa.get('uuid'),
      page: params.page,
      query: params.query,
    });
  }

  beforeModel() {
    if (
      !this.session.currentClient.can_view_financial_configurations ||
      !this.session.currentClient.can_view_financial_config_bank_accounts
    ) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller,model) {
    super.setupController(...arguments);
    controller.isBankAccountsEmpty ??= model.length === 0;
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        query: '',
        page: 1,
      });
    }
  }
}
