import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccountingExportNewController extends Controller {
  @service flashes;
  
  queryParams = ['page', 'chartOfAccountsId', 'selectionType'];
  chartsOfAccounts = null;

  @tracked exporting = false;
  @tracked page = 1;
  @tracked selectionsCount = 0;
  @tracked selectionType = 'kaseSelection';  
  @tracked showComponent = false;
  perPage = 10;
  
  get selectionComponent() {
    const selectionType = this.selectionType;
    if (selectionType == 'kaseSelection') {
      return 'accounting-exports/kase-selection-exporter';
    } else {
      return 'accounting-exports/misc-selection-exporter';
    }
  }

  async fetchKases() {
    this.store.query('v2/kase', {
      chartOfAccountsId: this.chartOfAccountsId,
      perPage: this.perPage,
      page: this.page,
      include: 'deceased,kase-financial,kase-financial.selections',
      exportable: true
    }).then((kasesOrSelections) => {
      set(this, 'kasesOrSelections', kasesOrSelections);
    });
  }

  fetchSelections() {
    this.store.query('v2/selection', {
      chartOfAccountsId: this.chartOfAccountsId,
      perPage: this.perPage,
      type: 'MiscSelection',
      page: this.page,
      include: 'purchaser',
      exportable: true
    }).then((kasesOrSelections) => {
      set(this, 'kasesOrSelections', kasesOrSelections);
    });
  }

  loadData() {
    this.selectionType === 'kaseSelection' ? this.fetchKases() : this.fetchSelections();
  }

  @action
  setPage(page) {
    set(this, 'page', page);
    this.loadData();
  }
  
  @action
  setChartOfAccounts(coaId) {
    this.page = 1;
    this.model.selections = [];
    this.selectionsCount = 0;
    if (coaId == null || coaId == undefined || coaId == '') {
      this.chartOfAccountsId = undefined;
      this.model.chartOfAccounts = null;
      this.kasesOrSelections = null;
      this.showComponent = false;
      return;
    } else if (isNaN(coaId)) {
      return;
    }

    const store = this.store;
    store.findRecord('v2/chart-of-accounts', coaId).then(record => {
      set(this, 'chartOfAccountsId', coaId);
      this.model.chartOfAccounts = record;
      this.showComponent = true;

      this.loadData();
    });
  }

  @action
  setType(type) {
    this.page = 1;
    this.model.selections = [];
    this.selectionsCount = 0;
    this.selectionType = type;
    this.loadData();
  }

  @action
  addSelection(selection) {
    const selections = this.model.selections;
    selections.pushObject(selection);
    this.selectionsCount = selections.content.length;
  }

  @action
  removeSelection(selection) {
    const selections = this.model.selections;
    selections.removeObject(selection);
    this.selectionsCount = selections.content.length;
  }

  @action
  export() {
    const warningMessage =
      this.selectionsCount == 0
        ? 'Are you sure you want to export all selections? This cannot be undone.'
        : 'Are you sure you want to export these selections? This cannot be undone.';
    if (confirm(warningMessage)) {
      this.exporting = true;
      const selectionType = this.selectionType;
      this.model
        .save({
          adapterOptions: {
            selectionType
          }
        })
        .then(() => {
          const store = this.store;
          this.model = store.createRecord('v2/accounting-export');
          this.transitionToRoute('authenticated.accounting.exports');
          this.exporting = false;
          this.showComponent = false;
        })
        .catch(ret => {
          ret.errors.forEach(error => {
            if (error.code == 428) {
              const redirectUrl = error['meta']['authorization-link'];
              window.location.replace(redirectUrl);
            } else {
              this.exporting = false;
              this.showComponent = false;
              this.send(
                'flashError',
                'Something went wrong with your export!'
              );
            }
          });
        });
    }
  }
}
