import { ActiveModelSerializer } from 'active-model-adapter';
 
export default class TributePayV1CheckSerializer extends ActiveModelSerializer {
    modelNameFromPayloadKey(key) {
        if(key == 'check') {
            return 'tribute-pay/v1/check';
        }
 
        return super.modelNameFromPayloadKey(...arguments);
      }
      
    payloadKeyFromModelName(modelName) {
        if (modelName === 'tribute-pay/v1/check') {
            return 'check';
        }
    
        return super.payloadKeyFromModelName(...arguments);
    }
   
}
