import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class KasesIntegrationFrazerRoute extends Route {
  @service() api
  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  setupController(controller, model) {
    super.setupController(...arguments);
    controller.set('kase', model);
    controller.set('frazerExport', this.store.queryRecord(
      'integrationExport', {
        kase_id: model.id,
        type: 'frazer',
        most_recent_export: true,
        status: 'successful'
      }) 
    );
    controller.set('events', this.store.query(
      'event', { kase_id: model.id })
    );
  }
}
