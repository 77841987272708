import Route from '@ember/routing/route';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';

export default class CraknClientsEditSoaConfigRoute extends Route {
  queryParams = {
    page: {
      refreshModel: true
    },
    per_page: {
      refreshModel: true
    },
    archived: {
      refreshModel: true
    }
  }

  @service store;

  async model(params) {
    const clientId = this.modelFor('authenticated.crakn.clients.edit').get('id');
    const client = await this.store.findRecord('v2/client', clientId);

    const soaTemplates = await this.store.query('v2/soaTemplate', {
      client_id: clientId,
      archived: false,
      page: params.page,
      per_page: 10
    });

    return {
      client,
      soaTemplates
    }
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    const newOrLastSoa = model.soaTemplates.any((template) => template.isNew) ||
    model.soaTemplates.meta.total === 1;

    controller.set('client', model.client);
    controller.set('soaTemplates', model.soaTemplates);
    controller.set('newOrLastSoa', newOrLastSoa);
  }

  resetController(controller, isExiting, transition) {
    if (isExiting) {
      const resetParams = ![
        'authenticated.crakn.manage.clients'
      ].includes(transition.targetName);

      if (resetParams) {
        controller.setProperties({
          page: 1
        });
      }
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
