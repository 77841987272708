export default [
  {
    id: 'draft',
    label: 'Draft'
  },
  {
    id: 'voided',
    label: 'Void'
  },

  {
    id: 'printed',
    label: 'Print'
  }
];
