import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class=\"flex flex--xs--a--c flex--xs--j-c flex--flush\">\n  <G::Crakn::Field::Input\n      class=\"c--xs--g\"\n      @dataTest={{@dataTest}}\n      @value={{this.currentValue}}\n      @disabled={{this.disabled}}\n      @showValidations={{@showValidations}}\n      @validationMessages={{@validationMessages}}\n      @placeholder= {{@placeholder}} />\n  <button\n      type=\"button\"\n      data-test=\"reveal\"\n      class=\"input__text--right btn btn--link light p--md--t {{unless this.canReveal \"hidden\"}}\"\n      {{on \"click\" (if @onReveal @onReveal (noop))}}>\n    <MdIcon @icon=\"visibility\" />\n  </button>\n</div>\n", {"contents":"<div class=\"flex flex--xs--a--c flex--xs--j-c flex--flush\">\n  <G::Crakn::Field::Input\n      class=\"c--xs--g\"\n      @dataTest={{@dataTest}}\n      @value={{this.currentValue}}\n      @disabled={{this.disabled}}\n      @showValidations={{@showValidations}}\n      @validationMessages={{@validationMessages}}\n      @placeholder= {{@placeholder}} />\n  <button\n      type=\"button\"\n      data-test=\"reveal\"\n      class=\"input__text--right btn btn--link light p--md--t {{unless this.canReveal \"hidden\"}}\"\n      {{on \"click\" (if @onReveal @onReveal (noop))}}>\n    <MdIcon @icon=\"visibility\" />\n  </button>\n</div>\n","moduleName":"crakn/components/g/crakn/field/secured-input.hbs","parseOptions":{"srcName":"crakn/components/g/crakn/field/secured-input.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';

export default class MaskingComponent extends Component {
  @service api;
  @service flashes;
  @service intl;
  @service session;

  get currentValue() {
    return this.args.data || this.paddedFragment;
  }

  set currentValue(value) {
    value = isEmpty(value) ? null : value;
    this.args.onUpdateData(value);
  }

  get canReveal() {
    return (
      this.session.currentUser.permissions > 1 &&
      this.args.dataFragment &&
      !this.args.data &&
      !this.args.disabled
    );
  }

  get disabled() {
    return this.session.currentUser.permissions <= 1 || this.canReveal || this.args.disabled;
  }

  get paddedFragment() {
    return this.args.dataFragment ? `${this.args.mask}${this.args.dataFragment}` : null;
  }

  @action
  willDestroy() {
    if (this.args.data && this.args.onDestroy) {
      this.args.onDestroy(this.args.data.slice(-4));
    }
    super.willDestroy()
  }

}
