import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedChecksCheckDetailsViewRoute extends Route {
  @service session;
  @service router;

  async model() {
    const check = await this.modelFor('authenticated.checks.check-details');
    let payee;
    if (check.payeeId) {
      if (check.payeeType == 'vendor') payee = await this.store.findRecord('v2/place', check.payeeId);
      else if (check.payeeType == 'contact') payee = await this.store.findRecord('person', check.payeeId);
    }
    else payee = null;
    return { check, payee };
  }

  beforeModel() {
    if (!(this.session.currentClient.can_view_financial_configurations && this.session.currentClient.can_view_financial_config_bank_accounts && this.session.currentClient.can_view_check_writing && this.session.currentUser.hasCheckAccess)){
        this.router.transitionTo('authenticated.dashboard');
    }
  }

  resetController(controller, isExiting) {  
    if (isExiting) {
      controller.showPayeeDetails = false;
    }
  }
}
