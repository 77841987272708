import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedAdminFinancialConfigurationsEditRoute extends Route {
  @service router;
  @service session;

  beforeModel(transition) {
    if (transition.to.name == 'authenticated.admin.financial-configurations.edit.index') {
      this.router.transitionTo('authenticated.admin.financial-configurations.edit.overview');
    }
  }
}
