import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';


export default class AdminUsersEditLocationAccessRoute extends SearchRoute {
  @service router;
  @service session;
  @service store;
  @service userConfig;
  @service api;

  queryParams = {
    page: {
      refreshModel: true
    },
    organization_id: {
      refreshModel: true
    },
    popout_organization_id: {
      refreshModel: true
    },
    location_name: {
      refreshModel: true
    },
    popout_location_name: {
      refreshModel: true
    }
  }

  async model(params) {
    const userId = this.modelFor('authenticated.admin.users.edit').get('id');
    return await this.store.query('v2/userLocation', {
      include: 'location,location.address',
      user_id: userId,
      archived: false,
      ...params
    });
  }

  modelType = 'v2/userLocation';
  routeRegex = /authenticated.admin.users.edit.location-access/;

  setupController(controller) {
    super.setupController(...arguments);
    controller.userId = this.modelFor('authenticated.admin.users.edit').get('id');
    controller.user = this.store.findRecord('v2/user', controller.userId);

    controller.getUnassignedLocations();
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.set('organization_id', null);
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
