import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknCheckTemplatesIndexRoute extends SearchRoute {
    @service store;
    
    model(params) {
        return this.store.query('tfe/document', {
            page: params.page,
            per_page: params.per_page,
            query: params.query,
            usage_type: 'check_template'    
        });
    }

    setupController(controller,model) {
        super.setupController(...arguments);
        controller.isCheckTemplatesEmpty ??= model.length === 0;
    }

    resetController(controller, isExiting) {
        super.resetController(...arguments);

        if (isExiting) {
            controller.setProperties({
                query: '',
                page: 1
            });
        }
    }
}
