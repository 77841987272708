import SearchController from 'crakn/controllers/search';
import { action, set } from '@ember/object';
import { alias } from '@ember/object/computed';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class AdminFinancialConfigurationsEditAccountsController extends SearchController {
  @service intl;

  @tracked archived = false;
  @tracked page = 1;
  @tracked per_page = 10;
  
  queryParams = [
    'archived',
    'order',
    'page',
    'per_page',
    'sort_by',
    'query',
  ];
  order = 'ASC';
  sort_by = null;

  @alias('model') accounts;

  @tracked chartOfAccounts = null;
  
  get chartOfAccountsId() {
    return this.chartOfAccounts.get('id');
  }

  get archivedAccounts() {
    return this.model.filter(account => account.archivedAt !== null);
  }

  get unarchivedAccounts() {
    return this.model.filter(account => account.archivedAt === null);
  }

  get hasNoArchivedAccounts() {
    return this.archived && this.archivedAccounts.length === 0;
  }

  @action
  save() {
    this.newAccount.then(account => {
      account.save().then(() => {
        this.send('resetAccount');
      });
    });
  }

  @action
  toggleArchived() {
    set(this, 'archived', !this.archived);
  }

  @action
  clearSearch() {
    this.clearQuery();
    this.chartOfAccounts = null;
  }
}
