import Model, { attr, belongsTo } from '@ember-data/model';

export default class TfeEnabledDocumentModel extends Model {
  @attr('boolean') esignEnabled;
  @attr('boolean') hasArchivedGeneratedDocuments;
  @attr('boolean') hasGeneratedDocuments;
  @attr('boolean') isFavorite;
  @attr('boolean', { defaultValue: false }) isPublic;

  @attr('string') description;
  @attr('string') documentId;
  @attr('string') name;
  @attr('string') latestRevisionStatus;
  @attr('string') secondaryRemoteIdentifier;
  @attr('string') secondaryRemoteIdentifierType;

  @belongsTo('tfe/latest-revision', { async: false }) latestRevision;
}
