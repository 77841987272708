import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class TableRowArchivedGlAccountComponent extends Component {
  @service flashes;
  @service intl;

  get account() {
    return this.args.account;
  }

  get deleteDialogPrompt() {
    const unarchiveDialog = this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.unarchiveDialog');
    return `${unarchiveDialog} ${this.account.name}?`;
  }

  get unarchiveText() {
    return this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.unarchive');
  }

  @action
  toggleArchive() {
    const account = this.account;

    set(account, 'archivedAt', null);
    account.save().then(() => {
      this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.messages.unarchive.success'));
    }).catch(() => {
      this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.gL-accounts.table.messages.unarchive.error'));
    });
    this.args.refreshModel();
  }
}
