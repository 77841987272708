import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import moment from 'moment';

export default class AuthenticatedChecksNewRoute extends Route {
  @service router;
  @service session;

  model(params) {
    return this.store.query('tribute-pay/v1/bank-account', {
      is_check_settings_enabled: true,
      per_page: null,
      ...params 
    });
  }

  beforeModel() {
    if (!(this.session.currentClient.can_view_financial_configurations && this.session.currentClient.can_view_financial_config_bank_accounts && this.session.currentClient.can_view_check_writing && this.session.currentUser.hasCheckAccess && this.session.currentUser.isStaff)){
        this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);
    controller.updateBankAccount(transition?.from?.queryParams?.bank_account_id);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      controller.setProperties({
        amount: 0,
        bankAccountId: null,
        date: moment(),
        dateRange: 90,
        payeeType: 'contact',
        memo: null,
        number: null,
        newPayee: null,
        payeeName: null,
        payeeId: null,
        showValidations: false,
        isCheckGenerationStarted: false
      });
    }
  }
}
