import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedChecksCheckDetailsViewController extends Controller{

  @tracked showPayeeDetails = false;

  get showPayeeDetailsToggler() {
    const payee = this.model.payee;
    return payee && (
      payee.addressLines||
      payee.cityStateZip ||
      payee.addressLine1 ||
      payee.contactCityState ||
      payee.phoneNumber ||
      payee.phonePrimary ||
      payee.email
    );
  }

  get payeeDetails() {
    const { check, payee } = this.model;
    const isVendor = check.payeeType === 'vendor';
    return {
      phoneNumber: isVendor ? payee?.phoneNumber : payee?.phonePrimary,
      email: payee?.email,
      singleLineAddress: payee.singleLineAddress,
      addressLine1: isVendor ? payee?.addressLines : payee?.addressLine1,
      addressLine2: isVendor ? payee?.cityStateZip : payee?.contactCityState,
    };
  }

}
