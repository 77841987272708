import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  locationId: validator('presence', {
    presence: true,
  }),
  'model.description': validator('presence', {
    presence: true
  }),
  'model.statementDescriptor': [
    validator('presence', {
      presence: true
    }),
    validator('length', {
      min: 5,
      max: 22
    }),
    validator('format', {
      regex: /^(?=.*[a-zA-Z])(?=.{5,22}$)(?!.*[<>'"*\\])[\x20-\x7E]*$/,
      message: 'Invalid statement descriptor. Please see requirements below and try again.'
    })
  ],
});
export default class AuthenticatedAdminTributePayPaymentsAccountsNewController extends Controller.extend(Validations) {
  @service router;
  @service store;
  @service flashes;
  @service intl;

  @tracked locationId;
  @tracked isBeginEnrollmentButtonDisabled = false;
  @tracked showValidations = false;

  @action
  cancel() {
    if (this.model.hasDirtyAttributes) {
      this.model.rollbackAttributes();
    }
    this.router.transitionTo('authenticated.admin.tribute-pay-payments.accounts.index');
  }

  @action
  beginEnrollment() {
    const location = this.store.peekRecord('location', this.locationId);

    const poBoxRegex = /(P\s*\.?\s*O\.?|Post\s*Office)\s*(Box|Bin)?\s*\d+/i
    if (poBoxRegex.test(location.line1)) {
      this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.new.errors.locationAddressLine1'));
      return;
    } else if (poBoxRegex.test(location.line2)) {
      this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.new.errors.locationAddressLine2'));
      return;
    }

    this.validate().then(({ validations }) => {
      if (validations.isValid) {
        set(this, 'showValidations', false);
        set(this, 'isBeginEnrollmentButtonDisabled', true);

        const { line1, line2, city, state, country, zipCode } = location;

        this.model.setProperties({
          addressLine1: line1,
          addressLine2: line2,
          city,
          state,
          country,
          postalCode: zipCode
        });

        this.model.save().then((account) => {
          if (account.tpsAgreementUrl) {
            window.open(account.tpsAgreementUrl, '_blank');
          }

          this.router.transitionTo('authenticated.admin.tribute-pay-payments.accounts.index');
          set(this, 'isBeginEnrollmentButtonDisabled', false);
        }).catch(() => {
          this.flashes.addError(this.intl.t('authenticated.admin.tribute-pay-payments.accounts.new.errors.beginEnrollment'));

          set(this, 'isBeginEnrollmentButtonDisabled', false);
        });
      } else {
        set(this, 'showValidations', true);
      }
    })
  }
}
