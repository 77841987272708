import Model, { attr } from '@ember-data/model';
import { tracked } from '@glimmer/tracking';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { gt } from '@ember/object/computed';
import { isEmpty } from '@ember/utils';
import { htmlSafe } from '@ember/template';
import { validator, buildValidations } from 'ember-cp-validations';
// eslint-disable-next-line ember/no-mixins
import CustomAction from 'crakn/mixins/custom-action';

const Validations = buildValidations({
  firstName: validator('presence', true),
  lastName: validator('presence', true),
  email: validator('format', {
    allowBlank: true,
    type: 'email',
  }),
  mobile: validator('format', {
    allowBlank: true,
    type: 'phone',
    message: 'This field must be a valid 10 digit phone number',
  }),
});

/** COMMENT: Still using mixins */
export default class UserModel extends Model.extend(CustomAction, Validations) {
  @attr('boolean') agreedToHyperlinkTermsOfUse;
  @attr('boolean') agreedToPrivacy;
  @attr('boolean') agreedToTermsOfUse;
  @attr('boolean') canAdjustInventory;
  @attr('boolean') canCreateSeedClient;
  @attr('boolean') canEditEvents;
  @attr('boolean') canEditGpls;
  @attr('boolean') canEditKaseCustomId;
  @attr('boolean') canEditLocations;
  @attr('boolean') canEditProducts;
  @attr('boolean') canEditRoles;
  @attr('boolean') canEditSelectionPrices;
  @attr('boolean') canEditUsers;
  @attr('boolean') canPostContractsAddOns;
  @attr('boolean') canSetKaseNumbering;
  @attr('boolean') canUnlockCase;
  @attr('boolean') canUnpostContractsAddOns;
  @attr('boolean') canUseQuickbooks;
  @attr('boolean') canViewBeta;
  @attr('boolean') canViewContactManager;
  @attr('boolean') canViewDoNotExport;
  @attr('boolean') canViewFinancial;
  @attr('boolean') canViewInventory;
  @attr('boolean', { defaultValue: true }) canViewKases;
  @attr('boolean', { defaultValue: false }) canViewKaseSettings;
  @attr('boolean') hasAuditLogPermission;
  @attr('boolean') hasChainOfCustodyEditPermission;
  @attr('boolean') hasContactManagerPermission;
  @attr('boolean') hasDoNotExportPermission;
  @attr('boolean') hasEditKaseCustomIdPermission;
  @attr('boolean') hasHideTributeBookPermission;
  @attr('boolean', { defaultValue: true }) hasFinancialPermission;
  @attr('boolean', { defaultValue: true }) hasFinancialReportPermission;
  @attr('boolean', { defaultValue: false }) hasInventoryPermission;
  @attr('boolean', { defaultValue: true }) hasKaseNumberingPermission;
  @attr('boolean') hasMiscSalesPermission;
  @attr('boolean', { defaultValue: true }) hasPostingPermission;
  @attr('boolean') hasSignature;
  @attr('boolean', { defaultValue: false }) hasUnlockLocationPermission;
  @attr('boolean', { defaultValue: true }) hasUnpostingPermission;
  @attr('boolean', { defaultValue: true }) showDashChainCustody;
  @attr('boolean', { defaultValue: true }) showDashEvents;
  @attr('boolean', { defaultValue: true }) showDashKases;
  @attr('boolean', { defaultValue: true }) showDashNav;
  @attr('boolean', { defaultValue: true }) showDashPastDue;
  @attr('boolean', { defaultValue: true }) showDashTasks;
  @attr('boolean', { defaultValue: true }) canSetupTributePay;
  @attr('boolean', { defaultValue: false }) canTurnOffTributeStore;
  @attr('boolean', { defaultValue: true }) canViewData;
  @attr('boolean', { defaultValue: true }) canViewAccounting;
  @attr('boolean', { defaultValue: false }) canViewDebugging;
  @attr('boolean', { defaultValue: true }) canViewCalendar;
  @attr('boolean', { defaultValue: false }) canViewCheckWriting;
  @attr('boolean', { defaultValue: true }) canViewContacts;
  @attr('boolean', { defaultValue: false }) canViewCustomCaseFields;
  @attr('boolean', { defaultValue: true }) canViewDisposition;
  @attr('boolean', { defaultValue: true }) canViewDocuments;
  @attr('boolean', { defaultValue: true }) canViewPrintables;
  @attr('boolean', { defaultValue: true }) canViewEvents;
  @attr('boolean', { defaultValue: true }) canViewFamilyFriends;
  @attr('boolean', { defaultValue: true }) canViewFamilyPortal;
  @attr('boolean', { defaultValue: true }) canViewFormsAdmin;
  @attr('boolean', { defaultValue: true }) canViewFinancialConfigurations;
  @attr('boolean', { defaultValue: true }) canViewFinancialDetail;
  @attr('boolean', { defaultValue: true }) canViewForms;
  @attr('boolean', { defaultValue: true }) canViewFormsAdmin;
  @attr('boolean', { defaultValue: true }) canViewGeneralPriceLists;
  @attr('boolean', { defaultValue: true }) canViewGroups;
  @attr('boolean', { defaultValue: true }) canViewGrowthEngine;
  @attr('boolean', { defaultValue: true }) canViewInfo;
  @attr('boolean', { defaultValue: true }) canViewInquiriesCase;
  @attr('boolean', { defaultValue: true }) canViewInquiriesGlobal;
  @attr('boolean', { defaultValue: true }) canViewLocations;
  @attr('boolean', { defaultValue: true }) canViewMiscTasks;
  @attr('boolean', { defaultValue: true }) canViewObituaryGenerator;
  @attr('boolean', { defaultValue: true }) canViewObituary;
  @attr('boolean', { defaultValue: true }) canViewOhioEdrs;
  @attr('boolean', { defaultValue: true }) canViewOnlinePlannerPlans;
  @attr('boolean', { defaultValue: true }) canViewOrganizations;
  @attr('boolean', { defaultValue: true }) canViewPayments;
  @attr('boolean', { defaultValue: true }) canViewPlaces;
  @attr('boolean', { defaultValue: true }) canViewPreneed;
  @attr('boolean', { defaultValue: true }) canViewPreparations;
  @attr('boolean', { defaultValue: true }) canViewReferralInfo;
  @attr('boolean', { defaultValue: true }) canViewServiceInfo;
  @attr('boolean', { defaultValue: true }) canViewReports;
  @attr('boolean', { defaultValue: true }) canViewTributeWebsite;
  @attr('boolean', { defaultValue: true }) canViewTransportation;
  @attr('boolean', { defaultValue: true }) canViewTributeVideo;
  @attr('boolean', { defaultValue: true }) canViewUsers;
  @attr('boolean', { defaultValue: true }) canViewVehicles;
  @attr('boolean', { defaultValue: true }) canViewWhiteboard;
  @attr('boolean', { defaultValue: true }) canViewVeteranInfo;
  @attr('boolean') isFuneralDirector;

  @attr('moment-datetime') archivedAt;

  @attr('number', { defaultValue: 0 }) permissions;

  @attr('phone') mobile;

  @attr('string-null-to-empty') defaultCountry;
  @attr('string-null-to-empty') defaultState;

  @attr('string') calendarPreference;
  @attr('string') communicationPreference;
  @attr('string') email;
  @attr('string') embalmerLicenseNumber;
  @attr('string') firstName;
  @attr('string') funeralDecisionsId;
  @attr('string') funeralDirectorLicenseNumber;
  @attr('string') imageUrl;
  @attr('string', { defaultValue: 'active' }) kaseFilterStatus;
  @attr('string') lastName;
  @attr('string') middleName;
  @attr('string') netsuiteId;
  @attr('string') netsuiteSubsidiaryId;

  @attr('boolean', { defaultValue: false }) isSuperuser;
  @gt('permissions', 2) isAdmin;
  @gt('permissions', 3) isOwner;
  @gt('permissions', 1) isStaff;

  @attr('string', { defaultValue: null }) ssoInviteUuid;
  @attr('string') uuid;

  @tracked modelName = null;

  get name() {
    return [this.firstName, this.lastName].filter(Boolean).join(' ');
  }

  get kaseEditRoute() {
    return 'authenticated.kases.manage.edit';
  }

  get initials() {
    return [this.firstName, this.middleName, this.lastName]
      .filter(Boolean)
      .map((name) => name[0].toUpperCase())
      .join('');
  }

  get hasAccountingAccess() {
    return this.canViewFinancial && this.isAdmin;
  }

  get hasCheckAccess() {
    return this.canViewCheckWriting || this.isAdmin || this.isOwner || this.isSuperuser 
  }

  get notifyEnabled() {
    const comPref = this.communicationPreference;

    return (
      (comPref === 'sms' && this.mobile) ||
      (comPref === 'email' && this.email) ||
      (comPref === 'both' && this.email && this.mobile)
    );
  }

  get hasEmailAndMobile() {
    return this.email && this.mobile ? true : false;
  }

  get safeImageUrl() {
    if (isEmpty(this.imageUrl)) {
      return null;
    }

    return htmlSafe(`background-image: url(${this.imageUrl})`);
  }

  get permissionName() {
    if (this.isSuperuser) {
      return 'Superuser';
    }

    switch (this.permissions) {
      case 4:
        return 'Owner/Manager';
      case 3:
        return 'Administrator';
      case 2:
        return 'Staff';
      case 1:
        return 'Support Staff';
      default:
        return 'No Access';
    }
  }

  // TODO: Write acceptance test once we can remove
  // the custom action mixin
  updatePassword(password) {
    const type = this.constructor.modelName;

    return this.customAction('update_password', 'POST', { password }).then(
      (result) => {
        this.store.pushPayload(type, result);
      }
    );
  }
}
