import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';

export default class AuthenticatedCraknClientsEditSoaConfigEditOverviewController extends Controller {
  @service router;
  @service intl;
  @service flashes;

  @controller('authenticated/crakn/clients/edit') edit;

  customFontSizes = [8, 9, 10, 11];

  @action
  setCustomFontSize(value) {
    this.model.customFontSize = Number(value);
  }

  @action
  cancel(template) {
    template.rollbackAttributes();
    this.router.transitionTo('authenticated.crakn.clients.edit.soa-config', this.model.client);
  }

  @action
  save(template, event) {
    event.preventDefault();

    template
      .save()
      .then(() => {
        this.flashes.addSuccess(this.intl.t('authenticated.crakn.clients.edit.soa-config.edit.save.success'));
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError(
          this.intl.t('authenticated.crakn.clients.edit.soa-config.edit.save.error')
        );
      });
  }
}
