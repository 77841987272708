import Component from '@ember/component';
import { set, get, computed } from '@ember/object';
import { inject as service } from '@ember/service';

export default Component.extend({
  store: service(),
  api: service(),

  tagName: 'li',
  selection: null,
  accountingExportSelections: null,
  accountingExportSelectionsCount: 0, // This is here to trigger a change
  isLoading: false,
  isLoaded: false,
  isOpen: false,
  items: null,
  qbdClassName: null,

  init() {
    this._super(...arguments);
    this.getItems();
  },

  async getItems() {
    await this.api.json.get(`v2/selections/${this.selection.id}/accounting_export_preview`).then((response) => {
      const parsedJson = response.parsedJson;
      set(this, 'items', parsedJson.selections);
      set(this, 'qbdClassName', parsedJson.qbd_class_name);
    });
  },

  selected: computed(
    'accountingExportSelections',
    'accountingExportSelectionsCount',
    'selection',
    function() {
      const selection = this.selection;
      const accountingExportSelections = this.accountingExportSelections.toArray();
      return accountingExportSelections.includes(selection);
    }
  ),

  actions: {
    toggleSelection() {
      const selected = this.selected;
      const selection = this.selection;
      if (selected) {
        this.sendAction('removeSelection', selection);
      } else {
        this.sendAction('addSelection', selection);
      }
    },

    toggleDrawer() {
      set(this, 'isOpen', !this.isOpen);
      if (!this.isLoaded || this.isLoading) {
        set(this, 'isLoading', true);
        const selection = this.selection;
        const store = this.store;

        store
          .findRecord('v2/selection', get(selection, 'id'), {
            reload: true
          })
          .then(() => {
            set(this, 'isLoading', false);
            set(this, 'isLoaded', true);
          });
      }
    }
  }
});
