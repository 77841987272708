import Route from '@ember/routing/route';

export default class AuthenticatedYellowReportRoute extends Route {
  layout = 'custom-full';
  queryParams = {
    organization_location_id: {
      refreshModel: true
    }
  }

  model(params) {
    const { organization_location_id } = params;
    return this.store.query('kaseTaskReport', {
      organization_location_id,
      page: 1,
      per_page: 10
    });
  }

  setupController(controller) {
    super.setupController(...arguments);
    const perPage = 10;

    controller.setProperties({
      kaseTaskReportPage: 1,
      organizationLocationId: this.organizationLocationId,
      perPage
    });
  }
}
