import Controller from '@ember/controller';
import { alias } from '@ember/object/computed';
import { inject as service } from '@ember/service';
import config from 'crakn/config/environment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { iifExtensions } from 'crakn/utils/data-helpers';

export default class AdminFinancialConfigurationsEditOverviewController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service router;
  @service session;
  @service taxVerbiage;

  @tracked showValidation = false;

  @alias('model') chartOfAccounts;

  get chartOfAccountsId() {
    const coa = this.chartOfAccounts;
    return coa.get('id');
  }

  get iifExtensions() {
    return iifExtensions;
  }

  get isQboCanada() {
    return this.model.isQBO && this.session.currentClient.operating_country === 'CA';
  }

  hostUrl = `${config.host}`;
  taxItems = [];

  @action
  cancel(chartOfAccounts) {
    chartOfAccounts.rollbackAttributes();
    this.router.transitionTo(
      'authenticated.admin.manage.financial-configurations.index'
    );
  }

  @action
  importQuickbooks() {
    this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.overview.messages.import.success'));

    this.api.json
      .post(`charts_of_accounts/${this.chartOfAccountsId}/import`)
      .then((response) => {
        if (!response.ok) {
          this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.overview.messages.import.error'));
        }
      });
  }

  @action
  importIIF(params) {
    this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.overview.messages.iifUpload.success'));

    this.api.json
      .post(`charts_of_accounts/${this.chartOfAccountsId}/import`, {
        body: {
          uuid: params.key.split('/')[2]
        }
      })
      .then((response) => {
        if (!response.ok) {
          this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.overview.messages.iifUpload.error'));
        }
      });
  }

  @action
  save() {
    this.model.validate().then(({ validations }) => {
      if (validations.isValid) {
        this.showValidation = false;
        this.model
          .save()
          .then(() => {
            this.flashes.addSuccess(this.intl.t('authenticated.admin.financial-configurations.edit.overview.messages.save.success'));
          })
          .catch(() => {
            this.flashes.addError(this.intl.t('authenticated.admin.financial-configurations.edit.overview.messages.save.error'));
          });
      } else {
        this.showValidation = true;
      }
    });
  }
}
