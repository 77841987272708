import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';

export default class AuthenticatedKasesManageIntegrationsIndexRoute extends Route {
  @service api;
  @service flashes;
  @service router;
  @service session;

  layout = 'custom-aside';

  model() {
    return this.modelFor('authenticated.kases.manage');
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const kaseId = model.get('id');

    const tributeWebsiteKaseIntegration = (await this.store.query('v2/kaseIntegration', {
      kase_id: kaseId,
      tribute_website_integration: true
    }))?.firstObject;
    const tributeWebsiteKaseExported = Boolean(tributeWebsiteKaseIntegration?.get('remoteId'));
    controller.set('tributeWebsiteKaseExported', tributeWebsiteKaseExported);

    if (this.session.currentClient.can_view_tribute_website && tributeWebsiteKaseExported) {
      this.api.json.get(`tribute_website/kases/${kaseId}/external_case`).then((response) => {
        controller.set('tributeWebsiteKase', response.ok ? response.parsedJson : undefined);
      }).catch(() => {
        this.flashes.addError('There was a problem getting the case from tribute-website.');
      })
    }

    controller.set('kaseId', kaseId);
    controller.set('tributeVideoExport', this.store.queryRecord('integrationExport', {
      kase_id: kaseId,
      type: 'tribute_video',
      most_recent_export: true
    }));
  }
}
