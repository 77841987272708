import Model, { belongsTo, attr } from '@ember-data/model';

export default class ReportConfigModel extends Model {
  @attr('boolean', { defaultValue: true }) canViewCovidReport;
  @attr('boolean') canViewArAging;
  @attr('boolean') canViewArAgingByKase;
  @attr('boolean') canViewArAgingByLocationAndCase;
  @attr('boolean') canViewArAgingByLocationAndCaseWithCredits;
  @attr('boolean') canViewArAgingWithCredits;
  @attr('boolean') canViewBatchPrintSoasWithBalance;
  @attr('boolean') canViewBeaconMonthlyReconciliationReport;
  @attr('boolean') canViewAltmeyerCaseCountByClientTypeReport;
  @attr('boolean') canViewCaseCountByCaseTagReport;
  @attr('boolean') canViewCaseRevenueOverviewReport;
  @attr('boolean') canViewDecedentReport;
  @attr('boolean') canViewDetailedItemsReport;
  @attr('boolean') canViewDetailedTaxableItems;
  @attr('boolean') canViewFamilyFriends;
  @attr('boolean') canViewFloridaCineratorReport;
  @attr('boolean') canViewFloridaMonthlyReport;
  @attr('boolean') canViewGraystoneReport;
  @attr('boolean') canViewGraystoneSurveyReport;
  @attr('boolean') canViewInventoryAsOf;
  @attr('boolean') canViewInventoryQuantity;
  @attr('boolean') canViewJcgAltmeyerReport;
  @attr('boolean') canViewJcgPreneedReport;
  @attr('boolean') canViewJcgReport;
  @attr('boolean') canViewMerchandiseProfit;
  @attr('boolean') canViewMiscSales;
  @attr('boolean') canViewMonthlyDeceased;
  @attr('boolean') canViewNetsuiteExceptionReport;
  @attr('boolean') canViewNextOfKinReport;
  @attr('boolean') canViewPaymentByLocationReport;
  @attr('boolean') canViewPaymentReport;
  @attr('boolean') canViewPrecoaDecedentReport;
  @attr('boolean') canViewPreneedFundingReport;
  @attr('boolean') canViewPreneedToAtneedConversionReport;
  @attr('boolean') canViewPreneedToAtneedRatioReport;
  @attr('boolean') canViewPurchaserReport;
  @attr('boolean') canViewReferralInfoReport;
  @attr('boolean') canViewSalesTax;
  @attr('boolean') canViewTennesseeCrematories;
  @attr('boolean') canViewTennesseeFuneralDirectors;
  @attr('boolean') canViewVaMonthlyVital;
  @attr('boolean') includePostingDateInPaymentByLocationReport;
  @attr('boolean') includePostingDateInPaymentReport;

  @attr('string') uuid;

  @belongsTo('client', { async: true }) client;
}
