import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { validator, buildValidations } from 'ember-cp-validations';

const Validations = buildValidations({
  stateSalesTaxRate: validator('number', {
    allowBlank: true,
    gte: 0.0,
    lte: 100,
  }),
  localSalesTaxRate: validator('number', {
    allowBlank: true,
    gte: 0.0,
    lte: 100,
  }),
});

export default class LocationModel extends Model.extend(Validations) {
  @attr('string') city;
  @attr('string') clientId;
  @attr('string') coaIntegrationName;
  @attr('string') color;
  @attr('string', { defaultValue: 'US' }) country;
  @attr('string') county;
  @attr('string') customId;
  @attr('string') defaultCremAuthHeaderId;
  @attr('string') defaultCremAuthHeaderUrl;
  @attr('string') defaultReceiptLogoId;
  @attr('string') defaultReceiptLogoUrl;
  @attr('string') defaultSfgsLogoId;
  @attr('string') defaultSfgsLogoUrl;
  @attr('string') defaultSoaLogoId;
  @attr('string') defaultSoaLogoUrl;
  @attr('string') email;
  @attr('string') kaseNumberingFormat;
  @attr('string') latitude;
  @attr('string') licenseNumber;
  @attr('string') line1;
  @attr('string') line2;
  @attr('string') longitude;
  @attr('string') name;
  @attr('string') owner;
  @attr('string') remoteId;
  @attr('string') state;
  @attr('string') timeZone;
  @attr('string') websiteUrl;
  @attr('string') zipCode;
  @attr('string') netsuiteId;

  @attr('boolean') coaCollectTax;
  @attr('boolean') hasAftercareIntegration;
  @attr('boolean') hasApprovedTributePayAccount;
  @attr('boolean') hasBassMollettIntegration;
  @attr('boolean') hasCfsIntegration;
  @attr('boolean') hasCiclopsIntegration;
  @attr('boolean') hasTributePrintIntegration;
  @attr('boolean') hasForethoughtIntegration;
  @attr('boolean') hasFrazerIntegration;
  @attr('boolean') hasFuneraloneIntegration;
  @attr('boolean') hasMessengerIntegration;
  @attr('boolean') hasPrecoaIntegration;
  @attr('boolean') hasRemoteId;
  @attr('boolean') hasTransnationalBankIntegration;
  @attr('boolean') hasTributeVideoIntegration;
  @attr('boolean') hasViewlogiesIntegration;
  @attr('boolean') kaseNumberingResetsMonthly;
  @attr('boolean') kaseNumberingResetsYearly;

  @attr('number') kaseNumberingStartingNumber;
  @attr('number') localSalesTaxRate;
  @attr('number') stateSalesTaxRate;
  @attr('number') tributeWebsiteLocationId;

  @attr('phone') faxNumber;
  @attr('phone') phoneNumber;

  @attr('moment-datetime') archivedAt;

  @belongsTo('generalPriceList', { async: true }) generalPriceList;
  @belongsTo('v2/contractTemplate') defaultContractTemplate;
  @belongsTo('v2/soaTemplate') defaultSoaTemplate;

  @hasMany('locationSpecialTax', { async: true }) locationSpecialTaxes;
  @hasMany('locationVehicles', { async: true }) locationVehicles;
  @hasMany('organization', { async: true }) organizations;

  get hasBasicAddressData() {
    if (this.line1 && this.city && this.zipCode && this.state) {
      return true;
    } else {
      return false;
    }
  }

  get singleLineAddress() {
    return [this.line1, this.line2, this.city, this.state, this.zipCode].filter(Boolean).join('+');
  }

  get singleLineStreet() {
    if (!this.line1 && !this.line2) {
      return '';
    }

    if (this.line1 && this.line2) {
      return `${this.line1} (${this.line2})`
    } else {
      return this.line1 || this.line2;
    }
  }

  get singleLineRegion() {
    if (!this.city && !this.state && !this.zipCode) {
      return '';
    }

    const cityState = [this.city, this.state].filter(Boolean).join(', ');
    return [cityState, this.zipCode].filter(Boolean).join(this.state ? ' ' : ', ');
  }
}
