import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';


export default class AuthenticatedCraknCheckTemplatesNewController extends Controller {
    @service api;
    @service flashes;
    @service intl;
    @service router;

    @tracked showValidation = false;

    @tracked documentFile = null;

    @action
    createTfeDocument(e) {
      e.preventDefault();
      e.target.disabled = true; // avoid double-click issues
  
      const isValid = this.validate();
      this.showValidation = !isValid;
  
      if (isValid) {
        this.postNewDocument();
      } else {
        e.target.disabled = false; // avoid double-click issues
      }
    }

    get documentNameMessage() {
      if (this.document && this.document.name) {
        return null;
      } else {
        return this.intl.t('authenticated.crakn.manage.check-templates.new.messages.fieldValidation');
      }
    }
    
    get documentFileMessage() {
      if (this.documentFile) {
        return null;
      } else {
        return this.intl.t('authenticated.crakn.manage.check-templates.new.messages.fieldValidation');
      }
    }

    validate() {
      return !(this.documentNameMessage || this.documentFileMessage);
    }

    async postNewDocument() {
      const formData = new FormData();
      formData.append('document[name]', this.document.name);
      formData.append('document[description]', this.document.description || '');
      formData.append('document[is_public]', this.document.isPublic);
      formData.append('document[usage_type]', 'check_template');
      formData.append('document[kase_contact_dropdown]', false);
      formData.append('document[event_dropdown]', false);
      formData.append('document[location_dropdown]', false);
      formData.append('document[inquiry_dropdown]', false);
      formData.append('document[esign_enabled]', false);
      formData.append('documentFile', this.documentFile);

      const response = await this.api.post('tfe/documents', { body: formData });
      const json = await response.json();

      if (response.ok) {
        this.flashes.addSuccess(this.intl.t('authenticated.crakn.manage.check-templates.new.messages.success'));
        this.router.transitionTo('authenticated.crakn.manage.check-templates.index');
      } else {
        let msg;
        if (json && json.errors) {
          msg = json.errors.join(', ');
        } else {
          msg = this.intl.t('authenticated.crakn.manage.check-templates.new.messages.failure');
        }

        this.flashes.addError(msg);
      }
    }
}
