import { get, action } from '@ember/object';
import Route from '@ember/routing/route';
import RSVP from 'rsvp';
import { inject as service } from '@ember/service';

export default class KasesIntegrationsBatesvilleRoute extends Route {
  @service api;
  layout = 'custom-aside';

  model() {
    const model = this.modelFor('authenticated.kases.manage');

    return RSVP.hash({
      model,
      kase: model,
      batesvilleExport: this.store.queryRecord('integrationExport', {
        kase_id: model.id,
        type: 'batesville',
        most_recent_export: true
      })
    });
  }

  setupController(controller, model, _transition) {
    super.setupController(...arguments);
    const events = this.store.query('event', { kase_id: get(model, 'kase.id') });
    controller.set('events', events)
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
