import SearchController from 'crakn/controllers/search';
import { inject as service } from '@ember/service';
import { sort } from '@ember/object/computed';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class AdminTfeFormsController extends SearchController {
  @service api;
  @service flashes;
  @service intl;
  @service session;

  @tracked enablingNewForm = false;
  @tracked formQuery = null;
  @tracked is_public = 'all';
  @tracked page = 1;
  @tracked query = null;

  queryParams = ['is_public', 'page', 'query'];
  formSorting = ['name:asc'];
  @sort('forms', 'formSorting') sortedForms;

  @action
  updateFormVisibilityQuery(query) {
    this.is_public = query;
    this.page = 1;
  }

  @action
  async enableNewForm(form) {
    if (this.forms.includes(form)) return;

    const response = await this.api.json.post('tfe/enabled_documents', {
      body: { document_id: form.id, client_id: this.session.currentClient.id }
    });

    if (response.ok) {
      this.flashes.addSuccess(
        this.intl.t(
          'authenticated.admin.manage.tfe.forms.messages.success.save'
        )
      );
      this.send('refreshModel');
    } else {
      this.flashes.addError(
        this.intl.t('authenticated.admin.manage.tfe.forms.messages.error')
      );
    }
  }

  @action
  cancelEnablingNewForm() {
    this.formQuery = null;
    this.enablingNewForm = false;
  }

  @action
  clearEnabledFormsQuery() {
    this.clearQuery();
    this.is_public = 'all';
  }

  @action
  async remove(form) {
    const response = await this.api.json.delete('tfe/documents/enable', {
      body: { document_id: form.id }
    });

    if (response.ok) {
      this.flashes.addSuccess(
        this.intl.t(
          'authenticated.admin.manage.tfe.forms.messages.success.delete'
        )
      );
      this.send('refreshModel');
    } else {
      this.flashes.addError(
        this.intl.t('authenticated.admin.manage.tfe.forms.messages.error')
      );
    }
  }

  @action
  async copyGlobalForm(form) {
    const response = await this.api.json.post(`tfe/documents/${form.id}/copy`, {
      body: {
        client_id: this.session.currentClient.id,
        document_name: form.name
      }
    });

    if (response.ok) {
      this.send('refreshModel');
      form.unloadRecord();
      this.enablingNewForm = false;
    } else {
      this.flashes.addError(
        this.intl.t('authenticated.admin.manage.tfe.forms.messages.error')
      );
    }
  }

  @action
  async onPollRevision(form) {
    return await form.latestRevision.reload();
  }
}
