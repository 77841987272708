import Model, { belongsTo, hasMany, attr } from '@ember-data/model';
import { equal } from '@ember/object/computed';
import { validator, buildValidations } from 'ember-cp-validations';

const QBO_CODE = 'quick_books_online';
const QBD_CODE = 'quick_books';
const SAGE50_CODE = 'sage50';
const IIF_CODE = 'quick_books_iif';
const SAGE_INTACCT_CODE = 'sage_intacct';

const Validations = buildValidations({
  name: validator('presence', true),
});

export default class ChartOfAccountsModel extends Model.extend(Validations) {
  @attr('string') uuid;
  
  @attr('array') locationConfiguration;

  @attr('string') sageIntacctJournalCode;
  @attr('string', { defaultValue: 'deceased' }) customerExportNameSrc;
  @attr('string', { defaultValue: 'deceased' }) customerExportAddressSrc;
  @attr('boolean', { defaultValue: true }) collectTax;
  @attr('boolean') exportPayments;
  @attr('boolean', { defaultValue: false }) qboLineItemRefunds;
  @attr('boolean', { defaultValue: false }) packageDiscountsAppliedBeforeTax;
  @attr('boolean', { defaultValue: false }) sfgsShowInterestsLineItem;
  @attr('boolean', { defaultValue: false }) soaUseExpandingInterests;
  @attr('boolean', { defaultValue: false }) trackLineItemClasses;
  @attr('boolean', { defaultValue: true }) showDeceasedName;
  @attr('boolean') validConfiguration;

  @attr('string') accountingIntegrationServiceId;
  @attr('string') accountingIntegrationServiceName;
  @attr('string') name;
  @attr('string') operatingCountry;
  @attr('string') qbdFilePath;

  @attr('moment-datetime') archivedAt;
  @attr('moment-datetime') updatedAt;
  @attr('moment-datetime') latestPostingPeriodLockedAt;

  @belongsTo('account') discountAccount;
  @belongsTo('integration-service') accountingIntegrationService;
  @belongsTo('account') receivablesAccount;
  @belongsTo('account') refundAccount;
  @belongsTo('account') taxAccount;
  @belongsTo('programmaticUser', { async: true }) programmaticUser;

  @belongsTo('taxItem', { async: true, inverse: null }) taxItem;
  @belongsTo('taxItem', { async: true, inverse: null }) localTaxItem; // for QBO Canada
  @belongsTo('taxItem', { async: true, inverse: null }) nonTaxItem;

  @hasMany('account') accounts;
  @hasMany('general-price-list') generalPriceLists;
  @hasMany('payment-type') paymentTypes;
  @hasMany('taxItem') taxItems;

  @equal('accountingIntegrationServiceName', QBD_CODE) isQBD;
  @equal('accountingIntegrationServiceName', QBO_CODE) isQBO;
  @equal('accountingIntegrationServiceName', IIF_CODE) isIIF;
  @equal('accountingIntegrationServiceName', SAGE50_CODE) isSage50;
  @equal('accountingIntegrationServiceName', SAGE_INTACCT_CODE) isSageIntacct;
}
