import Route from '@ember/routing/route'
import { inject as service } from '@ember/service';

export default class AdminLocationsEditOverviewRoute extends Route {
  @service store;

  async model() {
    const location = await this.modelFor('authenticated.admin.locations.edit');

    return {
      location: location,
      quickbooksConfig: await this.store.query('locationIntegrationService', {
        location_id: location.id,
        integration_service_name: 'quick_books'
      })
    };
  }

  async setupController(controller, model) {
    super.setupController(...arguments);

    const address = await model.location.address;
    controller.set('address', address);
  }
}
