import Controller from '@ember/controller';
import config from 'crakn/config/environment';

export default class ReportsController extends Controller {
  get canViewNoReports() {
    const reportKeys = [
      'canViewArAging',
      'canViewArAgingByLocationAndCase',
      'canViewBatchPrintSoasWithBalance',
      'canViewBeaconMonthlyReconciliationReport',
      'canViewAltmeyerCaseCountByClientTypeReport',
      'canViewCaseCountByCaseTagReport',
      'canViewCaseRevenueOverviewReport',
      'canViewCovidReport',
      'canViewDecedentReport',
      'canViewDetailedItemsReport',
      'canViewDetailedTaxableItems',
      'canViewFamilyFriends',
      'canViewFloridaCineratorReport',
      'canViewFloridaMonthlyReport',
      'canViewInventoryAsOf',
      'canViewInventoryQuantity',
      'canViewMerchandiseProfit',
      'canViewMonthlyDeceased',
      'canViewNextOfKinReport',
      'canViewPaymentByLocationReport',
      'canViewPaymentReport',
      'canViewPrecoaDecedentReport',
      'canViewPreneedFundingReport',
      'canViewPurchaserReport',
      'canViewReferralInfoReport',
      'canViewSalesTax',
      'canViewTennesseeCrematories',
      'canViewTennesseeFuneralDirectors',
    ]

    return reportKeys.every(key => this.get(`model.${key}`) === false);
  }

  get isEnvironmentEnterpriseOrLocalhost() {
    return config.host.includes('enterprise') || config.host.includes('localhost');
  }
}
