import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AuthenticatedCraknCheckTemplatesEditController extends Controller {
  @service api;
  @service flashes;
  @service intl;
  @service router;
  @service store;

  @tracked enabledDocumentClients = null;
  @tracked showValidation = false;

  documentFile = null;

  get enabledClientNames() {
    return this.enabledDocumentClients.map((client) => client.name);
  }

  get checkTemplateNameMessage() {
    if (this.checkTemplate && this.checkTemplate.name) {
      return null;
    } else {
      return this.intl.t(
        'authenticated.crakn.manage.check-templates.edit.messages.fieldValidation'
      );
    }
  }

  @action
  async downloadTest(checkTemplate) {
    const button = event.target;
    button.disabled = true;

    try {
      const response = await this.api.get(`tfe/documents/${checkTemplate.id}/download`);

      if (response.ok) {
        const blob = await response.blob();
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');

        a.href = url;
        a.download = `${checkTemplate.name}.pdf`;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        this.flashes.addError(this.intl.t('authenticated.crakn.manage.check-templates.edit.downloadTestForm.messages.failure'));
      }
    } catch (error) {
      this.flashes.addError(this.intl.t('authenticated.crakn.manage.check-templates.edit.downloadTestForm.messages.failure'));
    } finally {
      button.disabled = false;
    }
  }

  handleSaveError() {
    this.flashes.addError(
      this.intl.t('authenticated.crakn.manage.check-templates.edit.messages.failure')
    );
  }

  async postNewRevision() {
    const formData = new FormData();
    const latestRevision = this.checkTemplate.latestRevision;

    formData.append('revision[file]', this.documentFile);
    formData.append('revision[document_id]', this.checkTemplate.id);
    formData.append('revision[kase_contact_dropdown]', latestRevision.metadata.kaseContactDropdown || false);
    formData.append('revision[event_dropdown]', latestRevision.metadata.eventDropdown || false);
    formData.append('revision[location_dropdown]', latestRevision.metadata.locationDropdown || false);
    formData.append('revision[inquiry_dropdown]', latestRevision.metadata.inquiryDropdown || false);
    formData.append('revision[esign_enabled]', false);

    const response = await this.api.post('tfe/revisions', { body: formData });
    const json = await response.json();

    if (response.ok) {
      this.documentFile = null;
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.manage.check-templates.edit.messages.success'));
    } else {
      let msg;

      if (json && json.errors) {
        msg = json.errors.join(', ');
      } else {
        msg = this.intl.t('authenticated.crakn.manage.check-templates.edit.messages.failure');
      }

      this.flashes.addError(msg);
    }

    return response;
  }

  async putNewCheckTemplate() {
    const documentResponse = await this.checkTemplate.save();

    if (documentResponse) {
      let revisionResponse;

      if (this.documentFile) {
        this.checkTemplate.latestRevision.status = 'pending';
        this.checkTemplate.latestRevision.metadata.esignEnabled  = false;
        revisionResponse = await this.postNewRevision();
      } else {
        revisionResponse = await this.checkTemplate.latestRevision.save();
      }

      if (revisionResponse) {
        this.flashes.addSuccess(
          this.intl.t(
            'authenticated.crakn.manage.check-templates.edit.messages.success'
          )
        );
      } else {
        this.handleSaveError();
      }
    } else {
      this.handleSaveError();
    }
  }

  validate() {
    return !this.checkTemplateNameMessage;
  }

  @action
  updateBlankCheckTemplate(e) {
    e.preventDefault();

    const isValid = this.validate();
    this.showValidation = !isValid;

    if (isValid) {
      this.putNewCheckTemplate();
    }
  }

  @action
  cancel() {
    this.checkTemplate.rollbackAttributes();
    this.router.transitionTo('authenticated.crakn.manage.check-templates.index');
  }

  @action
  typeaheadSearch(value) {
    return this.store.query('client', { can_view_form_in_tfe: 'true', query: value });
  }

  @action
  async selectClient(client) {
    if (client === undefined) return;
    if (this.enabledClientNames.includes(client.name)) return;

    const response = await this.api.json.post('tfe/documents/enable', {
        body: { client_id: client.id, document_id: this.checkTemplate.id }
    });

    if (response.ok) {
      this.enabledDocumentClients = await this.store.query('tfe/client', { document_id: this.checkTemplate.id })
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.manage.check-templates.edit.addToClients.messages.success'));
    } else {
      this.flashes.addError(this.intl.t('authenticated.crakn.manage.check-templates.edit.addToClients.messages.failure'));
    }
  }

  @action
  async removeClient(tfeClient) {
    const response = await this.api.json.delete('tfe/documents/enable', {
        body: { tfe_client_id: tfeClient.id, document_id: this.checkTemplate.id }
    });

    if (response.ok) {
      this.enabledDocumentClients = await this.store.query('tfe/client', { document_id: this.checkTemplate.id })
      this.flashes.addSuccess(this.intl.t('authenticated.crakn.manage.check-templates.edit.removeFromClient.messages.success'));
    } else {
      this.flashes.addError(this.intl.t('authenticated.crakn.manage.check-templates.edit.removeFromClient.messages.failure'));
    }
  }
}
