import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import SearchController from 'crakn/controllers/search';

export default class AdminFinancialConfigurationsEditBankAccountsController extends SearchController {
  @service intl;
  @tracked isBankAccountsEmpty;

  get zeroBankAccountsMessage() {
    const { intl, isBankAccountsEmpty, search } = this;
    const zeroBankAccountsMatchingSearchMessage = intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.index.table.messages.zeroBankAccountsMatchingSearch');
    const zeroBankAccountsAddedMessage = intl.t('authenticated.admin.financial-configurations.edit.bank-accounts.index.table.messages.zeroBankAccountsAdded');

    return isBankAccountsEmpty
      ? (search
        ? zeroBankAccountsMatchingSearchMessage
        : zeroBankAccountsAddedMessage)
      : zeroBankAccountsMatchingSearchMessage;
  }

  @action
  scrollToTop() {
    window.scrollTo(0, 0);
  }
}
