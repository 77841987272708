import SearchRoute from 'crakn/routes/search';
import { inject as service } from '@ember/service';
export default class AuthenticatedPaymentsTransactionsRoute extends SearchRoute {
  @service store;

  queryParams = {
    query: {
      refreshModel: true
    },
    organizationLocationId: {
      refreshModel: true
    },
    paymentTypeId: {
      refreshModel: true
    },
    startsAt: {
      refreshModel: true
    },
    endsAt: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    perPage: {
      refreshModel: true,
    }
  }

  model(params) {
    return this.store.query('v2/transaction', params);
  }

  setupController(controller) {
    super.setupController(...arguments);

    this.store.query('payment-type', {
      organization_location_id: controller.organizationLocationId,
      per_page: 1000
    }).then((paymentTypes) => {
      controller.set('paymentTypes', paymentTypes);
    });
  }
}
