import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { set } from '@ember/object';

export default class CraknClientsFeatureConfigController extends Controller {
  @service api;
  @service clientConfig;
  @service flashes;
  @service session;

  @tracked searchValue;

  async handleSaveSuccess() {
    const updatedSession = await this.api.json.get('session');
    const session = updatedSession.parsedJson.session;
    set(this.session, 'currentClient', session.client);

    this.flashes.addSuccess('Client saved!');
  }

  handleSaveError() {
    this.flashes.addError('Oops! There was an error saving this client.');
  }

  async handleSaveSelectionValidationConfig() {
    if (this.selectionValidationConfig.hasDirtyAttributes) {
      await this.selectionValidationConfig.save();
    }
  }

  @action
  async save() {
    if (this.client.hasDirtyAttributes || this.selectionValidationConfig.hasDirtyAttributes) {
      await this.handleSaveSelectionValidationConfig();
      this.client
        .save()
        .then(() => this.handleSaveSuccess())
        .catch(() => this.handleSaveError());
    }
  }

  @action
  search(value) {
    this.clientConfig.searchFeatures(value);
  }

  @action
  filter(category) {
    this.clientConfig.filterFeatures(category);
  }
}
