import Service, { inject as service } from '@ember/service';

export default class TaxVerbiageService extends Service {
  @service intl;

  get localSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.local');
  }

  get stateSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.state');
  }

  get totalSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.total');
  }

  get defaultSelectionSummaryPlaceholder() {
    return this.intl.t('services.tax-verbiage.selectionSummary.default');
  }

  get locationLocalTaxRatePlaceholder() {
    return this.intl.t('services.tax-verbiage.location.edit.taxes.placeholders.local');
  }

  get locationStateTaxRatePlaceholder() {
    return this.intl.t('services.tax-verbiage.location.edit.taxes.placeholders.state');
  }

  get localProductAddEditPlaceholder() {
    return this.intl.t('services.tax-verbiage.product.local');
  }

  get stateProductAddEditPlaceholder() {
    return this.intl.t('services.tax-verbiage.product.state');
  }

  get coaTaxItemsPlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.taxItems');
  }

  get coaTaxItemNamePlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.taxItemName');
  }

  get coaNonTaxItemNamePlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.nonTaxItemName');
  }

  get coaSelectTaxItemPlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.selectTaxItem');
  }

  get coaSelectHstTaxItemPlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.selectHstTaxItem');
  }

  get coaSelectPstTaxItemPlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.selectPstTaxItem');
  }

  get coaHstPlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.hstItemName');
  }

  get coaPstPlaceholder() {
    return this.intl.t('services.tax-verbiage.chartOfAccounts.pstItemName');
  }
}
