import SearchRoute from 'crakn/routes/search';
import { action, set } from '@ember/object';
import { inject as service } from '@ember/service';

export default class AdminTfeFormsRoute extends SearchRoute {
  @service api;
  @service session;
  @service router;

  queryParams = {
    is_public: {
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    query: {
      refreshModel: true
    }
  };

  beforeModel() {
    if (!this.session.currentClient.can_view_form_in_tfe) {
      this.router.transitionTo('authenticated.admin.manage.forms');
    }
  }

  async model(params) {
    const documents = await this.store.query('tfe/document', {
      current_client: true,
      custom_upload: false,
      enabled: true,
      include: 'latest_revision',
      is_public: params.is_public,
      page: params.page,
      query: params.query
    });

    return { forms: documents.map(document => document), meta: documents.meta };
  }

  setupController(controller, model) {
    super.setupController(...arguments);

    set(controller, 'forms', model.forms);
    set(controller, 'meta', model.meta);
  }

  resetController(controller, isExiting) {
    if (isExiting) {
      set(controller, 'page', 1);
      set(controller, 'query', null);
    }
  }

  @action
  refreshModel() {
    this.refresh();
  }
}
