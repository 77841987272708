import { inject as service } from '@ember/service';
import SearchRoute from 'crakn/routes/search';

export default class AuthenticatedChecksIndexRoute extends SearchRoute {
  @service router;
  @service session;

  queryParams = {
    query: {
      replace: true,
      refreshModel: true
    },
    page: {
      refreshModel: true
    },
    archived: {
      type: 'boolean',
      refreshModel: true
    },
    sort_by: {
      refreshModel: true
    },
    order: {
      refreshModel: true
    },
    start_date: {
      refreshModel: true
    },
    end_date: {
      refreshModel: true
    },
    bank_account_id: {
      refreshModel: true
    },
    status: {
      refreshModel: true
    }
  };

  model(params) {
    return this.store.query('tribute-pay/v1/check', {
      ...params
    });
  }

  beforeModel() {
    if (
      !(
        this.session.currentClient.can_view_financial_configurations &&
        this.session.currentClient.can_view_financial_config_bank_accounts &&
        this.session.currentClient.can_view_check_writing &&
        this.session.currentUser.hasCheckAccess
      )
    ) {
      this.router.transitionTo('authenticated.dashboard');
    }
  }

  setupController(controller, model, transition) {
    super.setupController(...arguments);
    controller.isChecksEmpty ??= model.length === 0;
    if (!controller.bankAccountOptions) {
      const bankAccountOptions = this.store.query(
        'tribute-pay/v1/bank-account',
        { has_checks: true, per_page: null }
      );
      controller.bankAccountOptions = bankAccountOptions;
    }
    if (
      transition?.from &&
      transition.from.name !== 'authenticated.checks.index' &&
      transition.from.name !== 'authenticated.checks.new'
    ) {
      controller.setProperties({
        bank_account_id: null
      });
    }
  }

  resetController(controller, isExiting, transition) {
    super.resetController(...arguments);

    if (isExiting) {
      if (transition?.targetName !== 'authenticated.checks.new') {
        controller.setProperties({
          bank_account_id: null,
          bankAccountOptions: null
        });
      }

      controller.setProperties({
        query: '',
        page: 1,
        archived: false,
        sort_by: null,
        order: null,
        start_date: null,
        end_date: null,
        status: null
      });
    }
  }

}
