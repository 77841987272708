import Controller from '@ember/controller';
import { action, set, computed } from '@ember/object';
import { filterBy, mapBy } from '@ember/object/computed';
import { inject as controller } from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { debounce } from '@ember/runloop';

export default class CraknClientsEditSfgsConfigController extends Controller {
  @controller('authenticated/crakn/clients/edit') edit;

  @service flashes;
  @service session;
  @service router;
  @service intl;

  @tracked showValidation = false;
  @tracked template_query = '';
  @tracked temp_template_query = '';
  @tracked per_page = 10;
  @tracked originalContractTemplatesCount = null;

  @filterBy('contractTemplates', 'isNew', false) savedContractTemplates;
  @mapBy('systemSfgsTemplates', 'name') systemSfgsTemplateNames;

  menuPlacement = ['left', 'bottom', 'top', 'right'];
  queryParams = ['page', 'per_page', 'template_query'];
  offset = [70, -40];

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Invoice` : `Contract`;
  }

  get newOrLastContract() {
    if (this.template_query !== '' && this.originalContractTemplatesCount > 1) {
      return false;
    }
    return this.model.contractTemplates.any((template) => template.isNew) ||
      this.model.contractTemplates.meta.total === 1;
  }

  @computed('model.contractTemplates.meta.total', 'temp_template_query')
  get noSearchResults() {
    return this.model.contractTemplates.meta.total === 0 && this.temp_template_query !== '';
  }

  @action
  buildContractTemplate() {
    const template = this.store.createRecord('v2/contractTemplate', {
      client: this.client // client is required since this can be a TMS client
    });

    set(this, 'originalContractTemplatesCount', this.originalContractTemplatesCount + 1);

    this.transitionTo(template);
  }

  @action
  saveContractTemplate(template, event) {
    event.preventDefault();

    template.validate().then(({ validations }) => {
      if (validations.get('isValid')) {
        this.showValidation = false;

        template
          .save()
          .then(() => {
            this.flashes.addSuccess(`${this.contractLabel} Template saved!`);
            this.send('refreshModel');
          })
          .catch(() => {
            this.flashes.addError(
              `Oops! There was an error saving this ${this.contractLabel} Template.`
            );
          });
      } else {
        this.showValidation = true;
      }
    });
  }

  @action
  cancelContractTemplate(template) {
    template.rollbackAttributes();
  }

  @action
  deleteContractTemplate(template) {
    template
      .destroyRecord()
      .then(() => {
        this.flashes.addSuccess(`${this.contractLabel} Template deleted!`);
        set(this, 'originalContractTemplatesCount', this.originalContractTemplatesCount - 1);
        this.send('refreshModel');
      })
      .catch(() => {
        this.flashes.addError(
          `Oops! There was an error deleting this ${this.contractLabel} Template.`
        );
      });
  }

  @action
  transitionTo(template) {
    this.router.transitionTo('authenticated.crakn.clients.sfgs-config.edit.overview', template);
  }

  @action
  setTemplateQuery(value) {
    set(this, 'temp_template_query', value);

    debounce(this, this._setTemplateQuery, this.temp_template_query, 200);
  }

  @action
  clearSearch() {
    set(this, 'template_query', '');
    set(this, 'temp_template_query', '');
    this.router.refresh();
  }

  _setTemplateQuery(value) {
    set(this, 'template_query', value);
  }
}
