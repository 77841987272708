import { helper } from '@ember/component/helper';

const iconMap = {
    Archived: { type: 'SvgIcon', icon: 'inventory' },
    Created: { type: 'SvgIcon', icon: 'checkbook' },
    Downloaded: { type: 'MdIcon', icon: 'file_download', size: 'md' },
    Printed: { type: 'SvgIcon', icon: 'print-connect' },
    Updated: { type: 'SvgIcon', icon: 'undo' },
    Voided: { type: 'MdIcon', icon: 'block', size: 'sm' }, 
  };

export default helper(function auditLogIconHelper([action]) {
    return iconMap[action] || iconMap['Updated'];
});
