import { alias, readOnly, filter } from '@ember/object/computed';
import Controller from '@ember/controller';
import { action, get, set, computed } from '@ember/object';
import { inject as service } from '@ember/service';
import { isEmpty } from '@ember/utils';
import PersonSmartEntry from 'crakn/mixins/person-smart-entry';
import config from 'crakn/config/environment';
import { validator, buildValidations } from 'ember-cp-validations';
import { dateInCoaLockedPeriod } from 'crakn/utils/posting-period-locked';
import { tracked } from '@glimmer/tracking';

const Validations = buildValidations({
  'selection.contractDate': [validator('presence', true)]
});

export default class AuthenticatedKasesManageFinancialsSelectionIndexController extends Controller.extend(PersonSmartEntry, Validations) {
  @service store;
  @service api;
  @service session;
  @service flashes;
  @service intl;

  @tracked originalIsBadDebt = false;

  @alias('session.currentUser.id') currentUserId;
  @alias('clientInfo.logos') allLogos;

  @readOnly('selection.purchaser.id') purchaserId; // for validation

  @filter('allLogos', function(logo) {
    return logo.get('archivedAt') == null && logo.get('sfgs') == true;
  }) sfgsLogos;

  sortBy = ['createdAt'];
  hostUrl = `${config.host}`;

  customProduct = null;
  selection_id = null;
  generalPriceListId = null;
  editingEsignedContractId = null;
  viewingAuditTrailEsignedContractId = null;
  auditLogAction = null;

  saveFirst = false;
  isExportDetailsOpen = false;
  unpostingMode = false;
  showContractEsignControls = false;

  @computed('selection.packages.length')
  get sortedPackages() {
    const selectionPackages = get(this, 'selection.packages');

    if (isEmpty(selectionPackages)) {
      return [];
    } else {
      return selectionPackages.sortBy('createdAt');
    }
  }

  @computed('selection.customProducts.length')
  get sortedCustomProducts() {
    const selectionCustomProducts = get(this, 'selection.customProducts');

    if (isEmpty(selectionCustomProducts)) {
      return [];
    } else {
      return selectionCustomProducts.sortBy('createdAt');
    }
  }

  @computed('selection.products.length')
  get sortedProducts() {
    const selectionProducts = get(this, 'selection.products');

    if (isEmpty(selectionProducts)) {
      return [];
    } else {
      return selectionProducts.sortBy('createdAt');
    }
  }

  @computed(
    'sfgsLogos',
    'selectedContractTemplate.isCustom',
    'session.currentClient.can_use_custom_sfgs',
    'selection.isContract'
  )
  get canViewClientLogos() {
    const logos = this.sfgsLogos;
    const canUseCustomSFGS = get(this, 'selectedContractTemplate.isCustom');
    const isContract = get(this, 'selection.isContract');

    return !isEmpty(logos) && !canUseCustomSFGS && isContract;
  }

  @computed('selection.locked', 'selection_id')
  get canAddSelections() {
    return get(this, 'selection.locked') || !this.selection_id;
  }

  @computed('kase.status')
  get canEditFinancials() {
    return ['archived', 'lost'].indexOf(get(this, 'kase.status')) === -1;
  }

  @computed(
    'selection.{posted,exported,adjustments,payments}',
    'session.currentUser.canUnpostContractsAddOns',
    'canEditFinancials'
  )
  get canUnpostSelection() {
    const canEditFinancials = this.canEditFinancials;
    const canUnpostContractsAddOns = get(this, 'session.currentUser.canUnpostContractsAddOns');
    const exported = get(this, 'selection.exported');
    const posted = get(this, 'selection.posted');
    const noAdjustments = get(this, 'selection.adjustments.length') === 0;
    const noExportedPayments = get(this, 'selection.payments').findBy('exported', true) === undefined;
    const noRefunds = get(this, 'selection.refunds.length') === 0;

    return (
      canEditFinancials &&
      canUnpostContractsAddOns &&
      !exported &
      posted &&
      noExportedPayments &&
      noAdjustments &&
      noRefunds
    );
  }

  @computed(
    'selection.posted',
    'canEditFinancials',
    'session.currentUser.canPostContractsAddOns'
  )
  get canPostSelection() {
    const posted = get(this, 'selection.posted');
    const canEditFinancials = this.canEditFinancials;
    const canPostContractsAddOns = get(this, 'session.currentUser.canPostContractsAddOns');

    return !posted && canEditFinancials && canPostContractsAddOns;
  }

  @computed('selection.esignedContracts.@each.id')
  get isCreatingEsignedContract() {
    return get(this, 'selection.esignedContracts').any(doc => !doc.get('id'));
  }

  @computed('selection.contractEsignatureStatus')
  get esignatureStatusClass() {
    switch (get(this, 'selection.contractEsignatureStatus')) {
      case 'pending':
        return 'yellow';
      case 'complete':
        return 'green';
      case 'declined':
        return 'red';
      default:
        return '';
    }
  }

  @computed('selectedContractTemplateId')
  get selectedContractTemplate() {
    return this.store.peekRecord('v2/contractTemplate', this.selectedContractTemplateId);
  }

  @computed('selectedSoaTemplateId')
  get selectedSoaTemplate() {
    return this.store.peekRecord('v2/soaTemplate', this.selectedSoaTemplateId);
  }

  get smartEntryPersonMeta() {
    return {
      mother: { key: 'kase.mother', label: 'Mother' },
      father: { key: 'kase.father', label: 'Father' },
      spouse: { key: 'kase.spouse', label: 'Spouse' },
      informant: { key: 'kase.informant', label: 'Informant' },
      purchaser: {
        key: 'selection.purchaser',
        label: 'Purchaser',
        srcOptions: ['informant', 'mother', 'father', 'spouse']
      }
    };
  }

  get canUnExport() {
    return this.session.currentUser.hasDoNotExportPermission ||
    this.session.currentUser.isSuperuser;
  }

  get contractLabel() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Choose an Invoice or Add-on` : `Choose a Contract or Add-on`;
  }

  get addOnWording() {
    if (!this.selection.isContract) {
      return "Add-on";
    }
    return this.contractWording;
  }

  get contractWording() {
    return this.session.currentClient.relabel_contract_to_invoice ?
    `Invoice` : `Contract`;
  }

  get makeSelectionLabel() {
    const swap = this.session.currentClient.relabel_contract_to_invoice;
    return `Select ${swap ? 'an invoice' : 'a contract'} or add-on from above to add selections.`;
  }

  get isExportedToNetSuite() {
    return this.selection.netsuiteId && this.selection.netsuiteId !== '';
  }

  get isExportedToQbd() {
    return this.selection.qbdId && this.selection.qbdId !== '';
  }

  get isExportedToQbo() {
    return this.selection.qboId && this.selection.qboId !== '';
  }

  @action
  unpost() {
    if (dateInCoaLockedPeriod(this.selection, this.session, this.selection.get('contractDate'))) {
      this.flashes.addError(
        this.intl.t(
          'authenticated.kases.manage.financials.selection.errors.unlockContractPostingPeriod',
          { name: this.addOnWording.toLowerCase() }
        )
      );
      return;
    }

    let confirmation = true;
    if (get(this, 'selection.hasActiveEsignContracts')) {
      confirmation = confirm(
        `Unposting this selection will invalidate all ${this.contractText}s out for signature.  Are you sure you want to continue?`
      );
    }

    if (!confirmation) {
      return;
    }

    if(this.session.currentClient.require_reason_for_unposting && !this.unpostingMode) {
      set(this, 'unpostingMode', true);
      return;
    } else {
      this.send('saveUnpostReason');
    }
  }

  @action
  saveUnpostReason() {
    const selection = this.selection;

    if(this.unpostingMode && this.session.currentClient.require_reason_for_unposting) {
      const reasonForUnposting = selection.reasonForUnposting || '';

      const trimmed_reasonForUnposting = reasonForUnposting.trim();

      if (trimmed_reasonForUnposting.length == 0) {
        alert(this.intl.t('authenticated.kases.manage.financials.selection.errors.missingReason'));
        return;
      } else {
        set(selection, 'reasonForUnposting', trimmed_reasonForUnposting);
      }
    }

    set(selection, 'posted', false);
    selection.save()
      .then(() => {
        this.flashes.addSuccess(
          this.intl.t('authenticated.kases.manage.financials.selection.successful.unposted')
        );
        this.send('refreshKaseFinancial');
      })
      .catch(() => {
        this.flashes.addError();
        selection.rollbackAttributes();
      });

    set(this, 'unpostingMode', false);
  }

  @action
  cancelUnpost() {
    set(this, 'unpostingMode', false);
    this.selection.rollbackAttributes();
  }

  @action
  setSelectedContractTemplateId(templateId) {
    set(this, 'selectedContractTemplateId', templateId);
    set(this, 'showContractEsignControls', false);

    if (this.isCreatingEsignedContract) {
      this.selection.esignedContracts
        .filter(doc => !doc.get('id'))
        .forEach(doc => doc.rollbackAttributes());
    }
  }

  @action
  setSelectedSoaTemplateId(templateId) {
    set(this, 'selectedSoaTemplateId', templateId);
  }

  @action
  searchPurchaseReason(query) {
    return this.store
                .query('purchaseReason', {
                  query: query
                })
                .then((results) => {
                  return results.map((reason) => reason.name);
                });
  }

  @action
  searchEmbalmReason(query) {
    return this.store
                .query('embalmReason', {
                  query: query
                })
                .then((results) => {
                  return results.map((reason) => reason.name);
                });
  }

  @action
  createCustomProduct() {
    set(
      this,
      'customProduct',
      this.store.createRecord('selectionCustomProduct')
    );
  }

  @action
  validateAndSaveSelection(posting) {
    if (posting && dateInCoaLockedPeriod(this.selection, this.session, this.selection.get('contractDate'))) {
      this.flashes.addError(
        this.intl.t(
          'authenticated.kases.manage.financials.selection.errors.postingPeriodLockedContract',
          { name: this.addOnWording.toLowerCase() }
        )
      );
      return;
    }

    this.selection.validate().then(({ validations }) => {
      const errors = validations.get('errors');

      if (errors.length > 0) {
        set(this, 'saveFirst', true);
        set(this, 'showValidation', true);
      } else {
        set(this, 'saveFirst', false);
        set(this, 'showValidation', false);
        set(this, 'showContractEsignControls', false);
        this.send('saveSelection', posting);
      }
    });
  }

  @action
  setSelectedLogoAndSave(logoId) {
    set(this, 'selection.logoId', logoId);
    this.send('validateAndSaveSelection', false);
  }

  @action
  toggleContractEsignControls() {
    if (this.showContractEsignControls) {
      set(this, 'showContractEsignControls', false);
    } else {
      const selection = this.selection;

      selection.validate().then(({ validations }) => {
        const errors = validations.get('errors');

        if (!this.saveFirst) {
          set(this, 'saveFirst', get(selection, 'hasDirtyAttributes'));
        }

        if (errors.length > 0) {
          set(this, 'showValidation', true);
        } else {
          set(this, 'showValidation', false);

          if (!get(this, 'selection.esignedContracts.length')) {
            this.send('buildNewEsignedContract');
          }

          set(this, 'showContractEsignControls', true);
        }
      });
    }
  }

  @action
  buildNewEsignedContract() {
    const esignedContract = this.store.createRecord('esignedContract', {
      selection: this.selection,
      contractTemplate: this.selectedContractTemplate,
      remainingSignatureFieldNames: get(
        this,
        'selectedContractTemplate.esignatureFieldNames'
      )
    });

    this.send('editEsignedContract', esignedContract.get('id'));
  }

  @action
  editEsignedContract(id) {
    const isClosingCurrentEditing = id === this.editingEsignedContractId;

    const closeCurrentEditing = () =>
      set(this, 'editingEsignedContractId', null);
    const openNewEditing = () => set(this, 'editingEsignedContractId', id);

    isClosingCurrentEditing ? closeCurrentEditing() : openNewEditing();
    set(this, 'viewingAuditTrailEsignedContractId', null);
  }

  @action
  showAuditTrail(id) {
    const isClosingCurrentAuditTrail =
      id === this.viewingAuditTrailEsignedContractId;

    const closeCurrentAuditTrail = () =>
      set(this, 'viewingAuditTrailEsignedContractId', null);
    const openNewAuditTrail = () =>
      set(this, 'viewingAuditTrailEsignedContractId', id);

    isClosingCurrentAuditTrail
      ? closeCurrentAuditTrail()
      : openNewAuditTrail();
    set(this, 'editingEsignedContractId', null);
  }

  @action
  setPurchaserRelationship(relationship) {
    set(this, 'selection.purchaserRelationship', relationship);
    this.selection.save();
  }

  @action
  smartEntryPurchaser(srcPersonRole, destPersonRole) {
    set(this, 'saveFirst', true);
    this.send('smartEnterPerson', srcPersonRole, destPersonRole);
  }

  @action
  setContractDate(date) {
    set(this, 'saveFirst', true);
    const selection = this.selection;

    selection.set('contractDate', date);
    this.validate().then(({ validations }) => {
      if (get(validations, 'isValid')) {
        selection.save()
          .then(() => {
            this.flashes.addSuccess(
              `"${get(selection, 'name')}" has been updated!`
            );
          })
          .catch(() => {
            this.flashes.addError();
          });
      } else {
        set(this, 'showValidation', true);
      }
    });
  }

  @action
  async resendToBeaconfp() {
    await this.api.json.post(`selections/${this.selection.id}/resend_to_beaconfp`, {});
  }
}
