import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

export default class TableRowGlAccountListComponent extends Component {
  @tracked showDialog = false;

  get displayedId() {
    return this.args.account.generalLedgerId || this.args.account.remoteId;
  }

  @action
  toggleShowDialog() {
    this.showDialog = !this.showDialog;
  }
}
